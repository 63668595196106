import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PaymentState = {
  isLoading: boolean;
  clientSecret: string;
};

type ActionType = {
  setIsLoading: (state: PaymentState, { payload }: PayloadAction<boolean>) => void;
  setClientSecret: (state: PaymentState, { payload }: PayloadAction<string>) => void;
};

const defaultState: PaymentState = {
  isLoading: false,
  clientSecret: '',
};

export const paymentSlice = createSlice<PaymentState, ActionType>({
  name: 'consumerFlow/payment',
  initialState: defaultState,
  reducers: {
    setIsLoading(state: PaymentState, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setClientSecret(state: PaymentState, { payload }: PayloadAction<string>) {
      state.clientSecret = payload;
    },
  },
});
