import Box from '@material-ui/core/Box';
import ReactHtmlParser from 'html-react-parser';
import _ from 'lodash';
import React from 'react';

import { LocalizedInsuranceProduct } from '@breathelife/types';
import { List, ListItem, NavigationButtons, Text } from '@breathelife/ui-components';

import { SectionCard } from '../../Components/SectionCard';
import { text } from '../../Localization/Localizer';
import { LegacyProductInfo, RepeatableSection } from '../../Models/Product';
import { StepMetadata } from '../../types';

type Props = {
  selectedProduct: LegacyProductInfo | LocalizedInsuranceProduct;
  productSections: RepeatableSection[];
  onBackButtonClick: () => void;
  stepMetadata?: StepMetadata;
  onNextClick: () => void;
  isNextBtnLoading: boolean;
  nextStepButtonText?: string;
};

export function ProductSummary(props: Props): React.ReactElement {
  const {
    selectedProduct,
    productSections,
    onBackButtonClick,
    stepMetadata,
    onNextClick,
    isNextBtnLoading,
    nextStepButtonText,
  } = props;

  return (
    <React.Fragment>
      <Box px={{ xs: 3, md: 0 }}>
        <Text variant='copy'>
          {text('product.productSummary.intro', {
            carrierName: selectedProduct.carrierName,
            productName: selectedProduct.name,
          })}
        </Text>
      </Box>
      <Box mt={3}>
        {productSections.map((section, index) => (
          <SectionCard py={3} px={{ xs: 3, md: 0 }} key={`${index}-${_.kebabCase(section.title)}`}>
            <Text variant='title'>{section.title}</Text>
            {section.text && <Text variant='legal'>{section.text}</Text>}
            <SectionBody section={section} />
          </SectionCard>
        ))}
      </Box>
      <Box px={{ xs: 3, md: 0 }}>
        <NavigationButtons
          onPreviousClick={onBackButtonClick}
          hidePrevious={false}
          hideNext={false}
          nextButtonText={nextStepButtonText || stepMetadata?.continueButtonText || text('continue')}
          onNextClick={onNextClick}
          isNextBtnLoading={isNextBtnLoading}
        />
      </Box>
    </React.Fragment>
  );
}

export function SectionBody(props: { section: RepeatableSection }): React.ReactElement {
  const { section } = props;

  if (section.style === 'paragraph') {
    return (
      <React.Fragment>
        {section.subsections.map((subsection) => (
          <Text variant='small-body' key={subsection.title}>
            {ReactHtmlParser(subsection.title)}
          </Text>
        ))}
      </React.Fragment>
    );
  } else {
    return (
      <List noSpacing>
        {section.subsections.map((subsection) => (
          <ListItem key={subsection.title} iconType='checkmark'>
            <Box mb={1}>
              <Text noSpacing variant='small-body' grey={90}>
                {ReactHtmlParser(subsection.title)}
              </Text>
            </Box>
          </ListItem>
        ))}
      </List>
    );
  }
}
