export default {
  title: "Here's what we think you need in terms of coverage",
  subtitle: '',
  simpleQuoterTitle: 'Adjust your Life insurance',
  simpleQuoterSubtitle: '',
  recommendedCoverage: {
    label: 'Your recommended coverage',
    infoTitle: 'Recommended coverage:',
    infoText: 'This recommendation is based on: Debts + Education + Annual Income Needs - Savings',
  },
  selectedCoverage: {
    label: 'Your coverage is',
    sliderAmountText: 'Move the slider to see how the coverage amount can impact your monthly premium:',
    simpleQuoterSliderAmountText:
      'Move the slider to pick a coverage amount. The amount you pick will impact your monthly premium',
  },
  NA: 'N/A',
  perMonth: '/ mth',
  detailsButtonLabel: 'Details',
  applyButtonLabel: 'Apply',
  applyThroughAdvisorButtonLabel: 'Apply through an advisor',
  advisorWillBeInTouch: 'I will be in touch with you to explain your insurance options very shortly. Thanks!',
  premiumDisclosure:
    'Premium rates shown are for quoting purposes only. The insurer is not responsible for any errors displayed. The insurer will set the required premiums after reviewing the application. The required premium will be specified in the issued policy.',
  policyDetails: {
    title: 'Policy details',
    link: 'Read the full <a href="{{url}}" target="_blank" rel="noopener noreferrer">Insurance Policy</a>',
  },
  almostCompleted: 'You are only a few minutes away from completing your submission.',
  coverage: 'coverage',
  warning: {
    warningTitle: 'Warning',
    recommendedCoverageChange:
      'The coverage amount is different from your recommended coverage. To learn more, get in touch with an advisor.',
    coverageOverLimitOfProduct:
      'The recommended coverage amount exceeds the maximum for this product. To learn more, get in touch with an advisor.',
  },
  updatedPremium: 'Premium updated to {{amount}} / mth',
  error: {
    fetchQuotes: 'Error while retrieving quotes',
    fetchQuestionnaire: 'Error while retrieving questionnaire',
    fetchSummary: 'Error while retrieving summary',
    updatePremium: 'Failed to update premium',
    submission: 'Error while submitting the application',
  },
  getInTouch: 'Get In Touch',
  morning: 'Morning, 9am to 12pm',
  day: 'Daytime, 12pm to 6pm',
  evening: 'Evening, 6pm to 8pm',
  productSummary: {
    intro: "Let’s have a look at {{carrierName}}'s {{productName}} insurance.",
  },
  otherProducts:
    'We might have other products or coverage amounts that are better suited to you. To find out more, please contact us.',
  addons: {
    updatedPremium: {
      description: 'Your new premium is now:',
    },
    free: 'Free',
    includedOption: 'This option is already included in your policy',
    purchasableOption: 'Add this option ',
    forMoreInformation: 'Learn more',
  },
  applicationDetails: 'Application Details',
  yes: 'Yes',
  no: 'No',
  medicalExam: 'Medical Exam',
  untilAge: 'until age',
  coverageFlexibility: 'Coverage Flexibility',
  renewable: 'Renewable',
  convertible: 'Convertible',
  options: 'Options',
  simpleQuoterCalculate: 'Calculate',
  simpleQuoterUseCalculator: 'Use our calculator to estimate the amount of coverage you need.',
};
