import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { authenticationSlice, errorSlice } from '@breathelife/redux';
import { Loader } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import Urls from '../../Navigation/Urls';
import ApiService from '../../Services/ApiService';

export function ExternalApplicationAuthHandlerPage(): React.ReactElement {
  const dispatch = useDispatch();
  const { authToken } = useParams<{ authToken: string }>();

  const [appId, setAppId] = useState<string>('');
  const [errorRedirect, setErrorRedirect] = useState(false);
  const basePath = useCxSelector((state) => state.consumerFlow.navigation.basePath);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const externalAuthResponse = await ApiService.consumer.getApplicationByExternalAuth(authToken);
        const { applicationId, token } = externalAuthResponse.data;
        dispatch(authenticationSlice.actions.setToken({ token }));
        setAppId(applicationId);
      } catch (error) {
        const { message } = error;
        dispatch(errorSlice.actions.setError(message));
        setErrorRedirect(true);
      }
    })();
  }, [dispatch, authToken]);

  if (errorRedirect) {
    return <Redirect to={Urls.expired(basePath)} />;
  } else if (appId) {
    return <Redirect to={`${Urls.home(basePath)}?id=${appId}`} />;
  }

  return <Loader />;
}
