import _ from 'lodash';
import React, { useCallback } from 'react';
import Rheostat, { PublicState } from 'rheostat';
import 'rheostat/initialize';

import { RheoStatWrapper, SliderLabelsContainer } from './Styles';

type Props = {
  value: number;
  min: number;
  max: number;
  step: number;
  onDrag?: (data: number) => void;
  onChange: (data: number) => void;
  labels?: string[];
  currentTooltipText?: string;
};

export function getSnappedValue(value: number | undefined, min: number, max: number, step: number): number {
  if (_.isUndefined(value)) return min;
  if (value === min || value === max) return value;
  const roundedValue = _.round(value / step) * step;
  if (roundedValue > max) return max;
  if (roundedValue < min) return min;
  return roundedValue;
}

export function Slider(props: Props): React.ReactElement {
  const { value, min, max, step, labels, currentTooltipText, onChange, onDrag: onDragProp } = props;
  const sliderRefCallback = useCallback<(el: HTMLDivElement | null) => any>((el) => {
    if (el) {
      el.getElementsByClassName('DefaultHandle_handle')[0].setAttribute('data-tooltip-text', currentTooltipText || '');
    }
  }, []);

  const onMouseUp = useCallback(
    (data: PublicState) => {
      const newValue = getSnappedValue(data.values[0], min, max, step);
      onChange(newValue);
    },
    [onChange, min, max, step]
  );

  const onDrag = useCallback(
    (data: PublicState) => {
      if (!onDragProp) return;
      const newValue = getSnappedValue(data.values[0], min, max, step);
      onDragProp(newValue);
    },
    [onDragProp, min, max, step]
  );

  return (
    <div>
      <RheoStatWrapper ref={sliderRefCallback} hasTooltip={!!currentTooltipText}>
        <Rheostat
          aria-label='Slider handler'
          values={[value]}
          min={min}
          max={max}
          onChange={onMouseUp}
          onValuesUpdated={onDrag}
        />
      </RheoStatWrapper>

      {labels && (
        <SliderLabelsContainer data-testid='label-container'>
          {labels.map((label) => (
            <span key={label}>{label}</span>
          ))}
        </SliderLabelsContainer>
      )}
    </div>
  );
}
