import { replace as routerReplace } from 'connected-react-router';
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Questionnaire, SerializedNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { DebugToolbar } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { useCxSelector } from '../../Hooks/useCxSelector';
import Urls from '../../Navigation/Urls';
import { DebugToolbarModalContainer, DebugView } from './DebugToolbarModalContainer';

export type QuestionnaireResponse = {
  version: string;
  questionnaire: Questionnaire;
  nodeIdToAnswerPath?: SerializedNodeIdToAnswerPathMap;
  isLegacyQuestionnaire?: boolean;
};

export function DebugToolbarContainer(): React.ReactElement | null {
  const dispatch = useDispatch();
  const { enableQuestionnaireDebugTools } = useContext(CarrierContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [debugView, setDebugView] = useState<DebugView | undefined>(undefined);

  const basePath = useCxSelector((store) => store.consumerFlow.navigation.basePath);

  const onCreateNewApplication = useCallback(() => {
    dispatch(routerReplace(Urls.new(basePath)));
  }, [dispatch, basePath]);

  if (!enableQuestionnaireDebugTools) return null;

  return (
    <React.Fragment>
      <DebugToolbar
        onCreateNewApplication={onCreateNewApplication}
        onLoadPrefillSteps={() => {
          setDebugView(DebugView.stepFill);
          setIsOpen(true);
        }}
        onLoadNavigateSteps={() => {
          setDebugView(DebugView.stepList), setIsOpen(true);
        }}
      />
      {isOpen && debugView && <DebugToolbarModalContainer debugView={debugView} onClose={() => setIsOpen(false)} />}
    </React.Fragment>
  );
}
