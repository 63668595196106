import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { NodeIdAnswersResolver, NodeIdToAnswerPathMap, OnAnswerChange } from '@breathelife/questionnaire-engine';
import { Language, Answers } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import * as applicationOperations from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import { StepMetadata } from '../../types';
import { AddonsView } from './AddonsView';

type Props = {
  onBackButtonClick: () => void;
  stepMetadata?: StepMetadata;
  onAnswerChange: OnAnswerChange;
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap;
  answers: Answers;
  onNextClick: () => void;
  language: Language;
  nextStepButtonText?: string;
  isNextBtnLoading: boolean;
};

export function AddonsViewContainer(props: Props): React.ReactElement | null {
  const dispatch = useDispatch();

  const applicationState = useCxSelector((store) => store.consumerFlow.insuranceApplication);
  const applicationId = applicationState?.insuranceApplication?.id;
  const addonData = applicationState?.addonsMetadata;
  const monthlyPremium = applicationState?.insuranceApplication?.monthlyPremium;
  const referencePremium = applicationState?.referencePremium;

  const answersResolver = useMemo(
    () => new NodeIdAnswersResolver(props.nodeIdToAnswerPathMap || new Map()),
    [props.nodeIdToAnswerPathMap]
  );

  const selectedAddons: string[] = addonData
    ? answersResolver.getAnswer(props.answers, addonData.addonNodeId, {}) ?? []
    : [];

  useEffect(() => {
    if (typeof applicationId === 'undefined') return;
    dispatch(applicationOperations.getAddons(applicationId));
  }, [dispatch, applicationId]);

  if (applicationState.isLoading) {
    return <Loader />;
  }

  if (!addonData) return null;

  return (
    <AddonsView
      onBackButtonClick={props.onBackButtonClick}
      onNextClick={props.onNextClick}
      language={props.language}
      stepMetadata={props.stepMetadata}
      addons={addonData.addons}
      selectedAddons={selectedAddons}
      onAddonsChange={(selectedAddons: string[]) => {
        props.onAnswerChange(addonData.addonNodeId, selectedAddons);
      }}
      monthlyPremium={monthlyPremium}
      referencePremium={referencePremium}
      nextStepButtonText={props.nextStepButtonText}
      isNextBtnLoading={props.isNextBtnLoading}
    />
  );
}
