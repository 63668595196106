export default {
  modal: {
    title: 'Bonjour. Comment souhaitez-vous communiquer ?',
    emailUs: {
      single: 'Envoyez-moi un courriel',
      plural: 'Envoyez-nous un courriel',
    },
    legal: {
      life: '',
      retirement: '',
    },
    submit: 'Soumettre',
    subtitle: {
      single: {
        callYou: 'Veuillez me fournir vos coordonnées et je vous contacterai bientôt.',
        callUs: 'Rejoignez-moi par e-mail ou par téléphone via les actions en bas de cette page.',
      },
      plural: {
        callYou: 'Veuillez nous fournir vos coordonnées et nous vous contacterons bientôt.',
        callUs: 'Rejoignez-nous via les actions en bas de cette page.',
      },
    },
    confirmation: {
      single: {
        title: 'Je vous contacterai bientôt\xa0!',
        subtitle: 'Votre rêquete a bien été reçue. Je vous contacterai sous peu.',
      },
      plural: {
        title: 'Nous vous contacterons bientôt\xa0!',
        subtitle: 'Votre rêquete a bien été reçue. Nous vous contacterons sous peu.',
      },
    },
  },
  error: 'Erreur lors de la soumission des préférences de communication',
};
