import React from 'react';

import { CloudinaryConfig, InsuranceScopes } from '@breathelife/types';

import { QuoterPageProps } from '../Components/QuoterView/QuoterViewContainer';
import { ApplicationAssignee } from '../Models/ApplicationAssignee';
import { Features } from '../Models/Features';
import { Layout } from '../Models/Layout';
import { ErrorPageProps } from '../Pages/ErrorPage/ErrorPageContainer';
import { HomePageProps } from '../Pages/HomePage/HomePageContainer';
import { ConsumerFlowTheme } from '../types';

type CarrierScheduleCallButtonProps = {
  assignee: ApplicationAssignee;
  buttonText: string;
  onClick: () => void;
};

export type CarrierCustomizationProps = {
  Header: React.ComponentType<{ showScheduleCallAction: boolean }>;
  LeadsTableView: React.ComponentType;
  ScheduleCallView: React.ComponentType<any>;
  ScheduleCallButton: React.ComponentType<CarrierScheduleCallButtonProps>;
  LeftPanelInfoView: React.ComponentType;
  MobileHeaderInfoView: React.ComponentType;
  HomePage: React.ComponentType<HomePageProps> | null;
  ProductPage: React.ComponentType<QuoterPageProps>;
  ErrorPage: React.ComponentType<ErrorPageProps> | null;
};

export type ContextProps = {
  features: Features;
  layout: Layout;
  carrierInfo: {
    companyName: string;
    logo: string;
    logoCompact?: string;
    indexPageTitle: string;
    productTransitionLoadingDuration: Record<InsuranceScopes, number>;
    advisorTitleFallback: string;
    policyDetailsUrl: string;
    contactPhoneNumber?: string;
    contactEmail?: string;
    vendorCodeNodeId?: string;
  };
  cloudinaryConfig?: CloudinaryConfig;
  enableLoadLanguageSettingsFromDb?: boolean;
  enableLoadCarrierNamesFromDb?: boolean;
  enableLoadProductInformationFromDb: boolean;
  enableSalesDecisionRules: boolean;
  images: {};
  iconMap: Record<string, string>;
  canRestartAfterSessionExpired?: boolean;
  enableHeaderProductSummary: boolean;
  pdfFetchDuration?: number;
  theme: ConsumerFlowTheme;
  carrierCustomizations: CarrierCustomizationProps;
  interlocutorIds?: string[];
  enableQuestionnaireDebugTools?: boolean;
  displayFullAdvisorNameOnAdvisorGreetingMessage: boolean;
};

export const defaultFeatures: Features = {
  productPage: {
    enabled: false,
  },
  externalApplicationAuth: {
    enabled: false,
  },
  selfServeApplication: {
    enabled: false,
  },
  signature: {
    enabled: false,
  },
  payments: {
    enabled: false,
  },
};

export const CarrierContext = React.createContext({} as ContextProps);
