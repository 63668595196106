import { AccessTokenMethod } from '../Models/AccessTokenMethod';
import { ApplicationAssignee } from '../Models/ApplicationAssignee';
import { TimeFrame } from '../Models/TimeFrame';
import ApiService from './ApiService';

export type Schedules = {
  day: number;
  time: TimeFrame[];
}[];

export type PreferredMethods = Array<'phone' | 'email'>;

export type LeadCommunicationData = {
  email?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  preferredMethods: PreferredMethods;
  ['leads-communication-schedules']: Schedules;
};

export async function getApplicationAssignee(applicationId: string): Promise<ApplicationAssignee> {
  try {
    const assigneeData = await ApiService.getApplicationAssignee(applicationId);
    return assigneeData.data;
  } catch (error) {
    return {};
  }
}

export async function getAssigneeInfoFromToken(type: AccessTokenMethod, token: string): Promise<ApplicationAssignee> {
  // we need this map as the naming for the method is different in the backend
  const typesMap = {
    [AccessTokenMethod.privateLink]: 'private',
    [AccessTokenMethod.publicLink]: 'public',
    [AccessTokenMethod.email]: 'private',
  };

  try {
    const assigneeData = await ApiService.getAssigneeInfoFromToken(typesMap[type], token);
    return assigneeData.data;
  } catch (error) {
    return {};
  }
}

export async function updateLeadCommunication(
  appId: string,
  data: LeadCommunicationData
): Promise<LeadCommunicationData> {
  const leadCommunicationResponse = await ApiService.updateLeadCommunication(appId, data);
  return leadCommunicationResponse.data;
}

export async function getLeadCommunication(applicationId: string): Promise<LeadCommunicationData> {
  const leadCommunicationResponse = await ApiService.getLeadCommunication(applicationId);
  return leadCommunicationResponse.data;
}
