import * as Sentry from '@sentry/browser';
import { push as routerPush } from 'connected-react-router';
import { ConsumerFlowStore } from 'index';
import { Dispatch } from 'redux';

import { Theme } from '@breathelife/types';

import Urls from '../../Navigation/Urls';
import ApiService from '../../Services/ApiService';
import { themeSlice } from '../Theme/ThemeSlice';

const fallbackTheme = {
  colorRanges: {
    primary: {
      '10': '#f6f6f6',
      '20': '#eeeeee',
      '30': '#e6e6e6',
      '40': '#cccccc',
      '50': '#bcbcbc',
      '60': '#999999',
      '70': '#757575',
      '80': '#666666',
      '90': '#333333',
      default: '#bcbcbc',
    },
    secondary: {
      '10': '#f6f6f6',
      '20': '#eeeeee',
      '30': '#e6e6e6',
      '40': '#cccccc',
      '50': '#bcbcbc',
      '60': '#999999',
      '70': '#757575',
      '80': '#666666',
      '90': '#333333',
      default: '#bcbcbc',
    },
    tertiary: {
      '10': '#f6f6f6',
      '20': '#eeeeee',
      '30': '#e6e6e6',
      '40': '#cccccc',
      '50': '#bcbcbc',
      '60': '#999999',
      '70': '#757575',
      '80': '#666666',
      '90': '#333333',
      default: '#bcbcbc',
    },
    grey: {
      '0': '#ffffff',
      '10': '#f6f6f6',
      '20': '#eeeeee',
      '30': '#e6e6e6',
      '40': '#cccccc',
      '50': '#bcbcbc',
      '60': '#999999',
      '70': '#757575',
      '80': '#666666',
      '90': '#333333',
      '100': '#000000',
    },
  },
};

export const fetchTheme =
  () =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    try {
      const response = await ApiService.fetchTheme();
      dispatch(themeSlice.actions.setTheme({ theme: response.data }));
    } catch (error) {
      dispatch(themeSlice.actions.setHasErrorPayload({ hasLoadingError: true }));
      const message = `Unable to load theme in the consumer flow. Error: ${JSON.stringify(error)}`;
      Sentry.captureMessage(message, Sentry.Severity.Error);

      dispatch(themeSlice.actions.setTheme({ theme: fallbackTheme as Theme }));

      const { basePath } = store().consumerFlow.navigation;
      dispatch(routerPush(Urls.error(basePath)));
    }
  };
