import { Questionnaire, Subsection } from '@breathelife/questionnaire-engine';

import { Step } from '../Models/Step';

export function createQuestionnaireFromSubsection(step: Step | null): Questionnaire {
  return [
    {
      id: 'sectionGroup',
      blueprintId: 'b8e46054-64c7-4284-9cab-81c6a499da22',
      index: '1',
      title: { en: '', fr: '' },
      sections: [
        {
          id: 'section',
          blueprintId: '83bca9ad-a060-49e5-8057-9fe6f773a347',
          index: '1.1',
          documentTypes: [],
          title: { en: '', fr: '' },
          subsections: [step as Subsection],
        },
      ],
    },
  ];
}
