import React from 'react';
import styled, { useTheme } from 'styled-components';

import { SharedTheme } from '@breathelife/ui-components';

const ResultIconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  background: ${(props) => props.theme.colors.success}1A;
  box-shadow: 0 0 0 3px ${(props) => props.theme.colors.success};
`;

const DeniedSymbolBackground = styled(ResultIconBackground)`
  background: ${(props) => props.theme.colors.error}1A;
  box-shadow: 0 0 0 3px ${(props) => props.theme.colors.error};
`;

const ResultIconBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 88px;
  width: 88px;
  border: 1px solid ${(props) => props.theme.colors.grey[20]};
`;

function Checkmark(): React.ReactElement {
  return (
    <svg width='34' height='28' viewBox='0 0 34 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.0046 1.40437C34.0153 2.16621 34.217 3.60313 33.4551 4.6138L18.0791 25.012C17.5393 25.7254 16.8527 26.3145 16.0662 26.7404C15.2798 27.1663 14.4114 27.4192 13.5194 27.4828C12.6275 27.5463 11.732 27.419 10.8931 27.1092C10.0542 26.7993 9.29085 26.3139 8.65486 25.6849L8.65016 25.6802L0.712068 17.7834C-0.185215 16.8908 -0.189001 15.4398 0.703612 14.5425C1.59622 13.6453 3.04722 13.6415 3.94451 14.5341L11.8779 22.4262C11.8785 22.4268 11.8791 22.4274 11.8797 22.428C12.0498 22.5957 12.2547 22.7261 12.4811 22.8097C12.7083 22.8937 12.9514 22.9283 13.1937 22.911C13.436 22.8938 13.6713 22.8251 13.8837 22.7101C14.0954 22.5954 14.2791 22.4376 14.4231 22.2479C14.4235 22.2473 14.4227 22.2484 14.4231 22.2479L29.7952 1.85492C30.557 0.844245 31.9939 0.642525 33.0046 1.40437Z'
        fill='#39B54A'
      />
    </svg>
  );
}

function DeniedSymbol(): React.ReactElement {
  const theme = useTheme() as SharedTheme;

  return (
    <svg width='32' height='32' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.22207 7.00079L0.707072 2.48578C0.208229 1.98694 0.208228 1.17816 0.707072 0.679312C1.20592 0.180469 2.0147 0.180469 2.51354 0.679313L7.02855 5.19432L11.5465 0.676375C12.0453 0.177531 12.8541 0.177531 13.353 0.676374C13.8518 1.17522 13.8518 1.984 13.353 2.48285L8.83502 7.00079L13.3524 11.5182C13.8512 12.017 13.8512 12.8258 13.3524 13.3246C12.8535 13.8235 12.0447 13.8235 11.5459 13.3246L7.02855 8.80726L2.51412 13.3217C2.01528 13.8205 1.20649 13.8205 0.70765 13.3217C0.208807 12.8228 0.208807 12.0141 0.707651 11.5152L5.22207 7.00079Z'
        fill={theme.colors.error}
      />
    </svg>
  );
}

export function DeniedIcon(): React.ReactElement {
  return (
    <ResultIconBorder>
      <DeniedSymbolBackground>
        <DeniedSymbol />
      </DeniedSymbolBackground>
    </ResultIconBorder>
  );
}

export function SuccessIcon(): React.ReactElement {
  return (
    <ResultIconBorder>
      <ResultIconBackground>
        <Checkmark />
      </ResultIconBackground>
    </ResultIconBorder>
  );
}
