import { push } from 'connected-react-router';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/react-tracking';
import { setA11yMessage } from '@breathelife/redux';
import { Text, Title, TitleHelmet } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { a11yPageTitle, text } from '../../Localization/Localizer';
import Urls from '../../Navigation/Urls';
import { resetApplication as resetApplicationOperation } from '../../Redux/resetOperations';
import { Container, Content, ErrorImage, StartNowButton } from './Styles';

type Props = {
  image?: string;
  canRestartAfterSessionExpired?: boolean;
};

export function SessionExpired(props: Props): React.ReactElement {
  const dispatch = useDispatch();
  const basePath = useCxSelector((store) => store.consumerFlow.navigation.basePath);
  const applicationId =
    useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication?.id) ?? null;
  const stepId = useCxSelector((store) => store.consumerFlow.step.currentStep?.id);
  const redirectHome = useCallback(() => dispatch(push(Urls.home(basePath))), [dispatch, basePath]);
  const resetApplication = useCallback(() => {
    dispatch(resetApplicationOperation());
  }, [dispatch]);

  const pageTitle = a11yPageTitle('sessionExpired');
  const canGoBack = !!props.canRestartAfterSessionExpired;

  useEffect(() => {
    setA11yMessage(pageTitle);
  }, [pageTitle]);

  useEffect(() => {
    resetApplication();
    TypewriterTracking.viewedStep({
      hashedId: hash(applicationId),
      stepId: stepId ?? '',
    });
  }, [resetApplication, applicationId, stepId]);

  const image = props.image && <ErrorImage src={props.image} alt={text('error')} />;

  let content: JSX.Element;
  if (canGoBack) {
    content = (
      <Content>
        <Title level={1}>{text('sessionExpired.title')}</Title>
        <Text align='center'>{text('sessionExpired.description')}</Text>
        <Text align='center'>{text('sessionExpired.goBack')}</Text>
        {image}
        <StartNowButton onClick={redirectHome}>
          <span>{text('sessionExpired.startOver')}</span>
        </StartNowButton>
      </Content>
    );
  } else {
    content = (
      <Content>
        <Title level={1}>{text('sessionExpired.titleNoReturn')}</Title>
        <Text align='center'>{text('sessionExpired.descriptionNoReturn')}</Text>
        {image}
      </Content>
    );
  }

  return (
    <React.Fragment>
      <TitleHelmet title={pageTitle} />
      <Container>{content}</Container>
    </React.Fragment>
  );
}
