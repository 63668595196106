import React from 'react';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { ModalType } from '../../Models/Layout';
import { ApplyThroughAdvisorModalContainer } from './ContactModal/ApplyThroughAdvisorModalContainer';
import { ContactModalContainer } from './ContactModal/ContactModalContainer';

const modalLookup = {
  [ModalType.contact]: ContactModalContainer,
  [ModalType.applyThroughAdvisor]: ApplyThroughAdvisorModalContainer,
  [ModalType.continueLater]: () => null,
};

export function Modal(): React.ReactElement | null {
  const modalState = useCxSelector((store) => store.consumerFlow.layout.modalState);

  if (!modalState.isOpen || !modalState.type) return null;

  return React.createElement(modalLookup[modalState.type]);
}
