import React, { useContext } from 'react';

import { AdvisorInfoPanel } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { useCxSelector } from '../../Hooks/useCxSelector';

export function AdvisorInfoPanelContainer(): React.ReactElement | null {
  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);
  const { carrierInfo } = useContext(CarrierContext);

  if (!applicationAssignee?.name) return null;

  return (
    <AdvisorInfoPanel
      advisorInfo={{
        name: applicationAssignee.name,
        title:
          applicationAssignee.subgroup?.name || applicationAssignee.group?.name || carrierInfo.advisorTitleFallback,
      }}
    />
  );
}
