import HtmlReactParser from 'html-react-parser';
import queryString from 'query-string';
import React from 'react';

import { PlanFinderFooter as PlanFinderBaseFooter } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { text } from '../../Localization/Localizer';

function getLegalItems(scope?: string): string[] {
  const legalItemDefinitions = text('legal.footer');
  const legalItemKeys = Object.keys(text('legal.footer'));

  return legalItemKeys.map((itemKey: string) => {
    const legalItem = legalItemDefinitions[itemKey];

    if (typeof legalItem === 'object') {
      if (scope && Object.keys(legalItem).includes(scope)) {
        return legalItem[scope];
      }

      return '';
    }

    return text(`legal.footer.${itemKey}`);
  });
}

export function PlanFinderFooter(): React.ReactElement {
  const applicationScope = useCxSelector(
    (store) => store.consumerFlow.insuranceApplication.insuranceApplication?.insuranceScopes
  )?.[0];

  const { insuranceScopes } = queryString.parse(location.search) as Record<string, string | undefined>;
  const queryScope = insuranceScopes?.split(',')[0];
  const scope = applicationScope ?? queryScope;

  const legalItems = getLegalItems(scope);

  return (
    <PlanFinderBaseFooter>
      {legalItems.map((item, index) => (
        <React.Fragment key={index}>{HtmlReactParser(item)}</React.Fragment>
      ))}
    </PlanFinderBaseFooter>
  );
}
