import { AxiosResponse } from 'axios';

import {
  createCryptoMaterial,
  CryptoMaterial,
  CryptoMaterialInput,
  SubmitResponse,
} from '@breathelife/crypto-material';

import { ApplicationState } from '../Models/ApplicationState';
import { SignResponse } from '../Models/Submission';
import { ErrorStep } from '../Redux/Submission/SubmissionSlice';
import ApiService from './ApiService';
import { SubmissionError } from './Errors/SubmissionError';

export type ProductQuote = {
  productId: string;
  premium: number;
};

export async function submitNeedsAnalysis(
  data: ProductQuote & { coverageAmount: number; appId: string }
): Promise<{ application: ApplicationState; redirectUrl: string }> {
  const response = await ApiService.submitNeedsAnalysis(data.appId, data.coverageAmount, data.productId, data.premium);
  return response.data;
}

export async function submitApplication(applicationId: string): Promise<SubmitResponse> {
  try {
    const response: AxiosResponse<SubmitResponse> = await ApiService.submitInsuranceApplication(applicationId);
    return response.data;
  } catch (err) {
    throw new SubmissionError(ErrorStep.submission);
  }
}

export function generateCryptoMaterial(cryptoMaterialInput: CryptoMaterialInput): CryptoMaterial {
  try {
    return createCryptoMaterial(cryptoMaterialInput);
  } catch (err) {
    throw new SubmissionError(ErrorStep.crypto);
  }
}

export async function signApplicationUsingCrypto(
  submissionId: string,
  cryptoMaterial: CryptoMaterial
): Promise<SignResponse> {
  try {
    const signResult: AxiosResponse<SignResponse> = await ApiService.signApplicationUsingCrypto(
      submissionId,
      cryptoMaterial
    );
    return signResult.data;
  } catch (err) {
    throw new SubmissionError(ErrorStep.signature);
  }
}

export async function submitApplicationForExternalSignature(applicationId: string): Promise<void> {
  try {
    await ApiService.submitApplicationForExternalSignature(applicationId);
  } catch (err) {
    throw new SubmissionError(ErrorStep.signature);
  }
}
