import styled, { css } from 'styled-components';

const tooltipCss = css`
  &:after {
    content: none;
  }

  &:before {
    z-index: 1;
    position: absolute;
    opacity: 1;
    content: attr(data-tooltip-text);
    font-weight: ${(props) => props.theme.fonts.weight.semibold};
    text-align: center;
    height: 20px;
    width: 100px;
    top: -30px;
    left: -34px;
    background-color: transparent;
  }
`;

export const RheoStatWrapper = styled.div<{ hasTooltip?: boolean }>`
  position: relative;
  height: 50px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    height: 50px;
  }

  .DefaultBackground {
    position: relative;
    height: 8px;
    background-color: ${(props) => props.theme.colors.grey[20]};
    top: 25px;
    left: 0;
    border: 1px solid ${(props) => props.theme.colors.grey[50]};
    border-radius: 10px;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      top: 25px;
    }
  }

  .handleContainer {
    .DefaultHandle_handle__horizontal {
      margin-left: -12px;
      top: 12px;
    }

    .DefaultHandle_handle {
      outline: none;
      z-index: 2;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      background-color: ${(props) => props.theme.colors.grey[0]};
      border: 2px solid ${(props) => props.theme.colors.primary.default};
      border-radius: 50%;
      height: 32px;
      width: 32px;
      padding: 0px;
      @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
        height: 32px;
        width: 32px;
      }

      &:focus,
      &:hover {
        border-width: 3px;
      }

      ${(props) => props.hasTooltip && tooltipCss};
    }
  }

  .DefaultProgressBar_progressBar {
    position: absolute;
    height: 8px;
    background-color: ${(props) => props.theme.colors.primary[40]};
    border: 1px solid ${(props) => props.theme.colors.primary.default};
    border-radius: 4px;
    top: 25px;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      top: 25px;
    }
  }
`;

export const SliderLabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
`;
