import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useCallback } from 'react';

import { QuotedInsuranceProduct } from '@breathelife/types';
import { Text, Title } from '@breathelife/ui-components';

import { Button } from '../../../Components/Button';
import { CurrencyText } from '../../../Components/CurrencyText/CurrencyText';
import { getRepeatableSections } from '../../../Helpers/products';
import { CenteredLayout } from '../../../Layouts/Centered/Layout';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { text } from '../../../Localization/Localizer';
import { LegacyQuotedProduct } from '../../../Models/Product';
import { AlmostCompletedMessage } from './AlmostCompletedMessage';
import { Options } from './Options';
import { PolicyDetails } from './PolicyDetails';
import { ProductDetailsInfo } from './ProductDetailsInfo';
import { RepeatableSections } from './RepeatableSections';
import { CenteredLayoutWrapper, Content, DetailsContainer, OffsetLogo, OffsetLogoWrapper } from './Styles';

type ProductDetailsModalProps = {
  isOpen: boolean;
  isSelfServeBlocked: boolean;
  closeModal: () => void;
  product?: LegacyQuotedProduct | QuotedInsuranceProduct;
  coverageAmount: number;
  carrierLogo: string;
  carrierInfo: {
    companyName: string;
  };
  policyDetailsUrl?: string;
  onApply: (product: LegacyQuotedProduct | QuotedInsuranceProduct) => void;
  onApplyThroughAdvisor: (productId: string) => void;
  productLogo?: string;
};

export function ProductDetailsModal(props: ProductDetailsModalProps): React.ReactElement | null {
  const {
    closeModal,
    carrierLogo,
    coverageAmount,
    isOpen,
    policyDetailsUrl,
    productLogo,
    product,
    carrierInfo,
    onApply: onApplyProp,
    onApplyThroughAdvisor: onApplyThroughAdvisorProp,
  } = props;

  const onApply = useCallback(() => {
    if (!product || !product.premium) return;
    onApplyProp(product);
  }, [product, onApplyProp]);

  const onApplyThroughAdvisor = useCallback(() => {
    if (!product) return;
    closeModal();
    onApplyThroughAdvisorProp(product.id);
  }, [closeModal, onApplyThroughAdvisorProp, product]);

  if (!product || _.isNil(product.premium)) return null;

  const companyName = product.carrierName || carrierInfo.companyName;

  return (
    // TODO: add close text on close button of Modal Layout
    <ModalLayout
      isOpen={isOpen}
      closeModal={closeModal}
      title=''
      submitButton={null}
      bodyVariant='no-padding'
      fullScreen
    >
      <CenteredLayoutWrapper>
        <CenteredLayout
          title=''
          isLoading={false}
          headerVariant='none'
          offsetContent={
            <OffsetLogoWrapper>
              <OffsetLogo
                src={productLogo || carrierLogo}
                alt={text('logo', {
                  companyName,
                })}
              />
            </OffsetLogoWrapper>
          }
        >
          <Content px={3}>
            <Box mb={1}>
              <Title level={1}>{text(`carriers.${product.carrierId}`)}</Title>
            </Box>
            <Box mb={2}>
              <Text noSpacing grey={80}>
                {product.name}
              </Text>
            </Box>
            <Box mb={4}>
              <Box mb={1}>
                <CurrencyText amount={coverageAmount} suffix={text('product.coverage')} precision={0} />
              </Box>
              <CurrencyText amount={product.premium} suffix={text('product.perMonth')} />
            </Box>
            <Box mb={3}>
              {props.isSelfServeBlocked ? (
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  minWidth={140}
                  disabled={!product.premium}
                  onClick={onApplyThroughAdvisor}
                >
                  {text('product.getInTouch')}
                </Button>
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  minWidth={140}
                  disabled={!product.premium}
                  onClick={onApply}
                >
                  {text('product.applyButtonLabel')}
                </Button>
              )}
            </Box>
            <Divider />
            {<ProductDetailsInfo product={product} />}
          </Content>
          {'details' in product && product.details && (
            <DetailsContainer>
              <RepeatableSections repeatableSections={getRepeatableSections(product, 'preview')} />
              <Options options={'details' in product && product.details ? product.details.options : []} />
            </DetailsContainer>
          )}
          {!!policyDetailsUrl && <PolicyDetails url={policyDetailsUrl} />}
          <AlmostCompletedMessage />
          <Content px={3}>
            {props.isSelfServeBlocked ? (
              <Box mb={4}>
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  minWidth={140}
                  disabled={!product.premium}
                  onClick={onApplyThroughAdvisor}
                >
                  {text('product.getInTouch')}
                </Button>
              </Box>
            ) : (
              <React.Fragment>
                <Box mb={2}>
                  <Button variant='contained' color='primary' size='large' fullWidth onClick={onApply}>
                    {text('product.applyButtonLabel')}
                  </Button>
                </Box>
                <Box mb={4}>
                  <Button variant='outlined' color='primary' size='large' fullWidth onClick={onApplyThroughAdvisor}>
                    {text('product.applyThroughAdvisorButtonLabel')}
                  </Button>
                </Box>
              </React.Fragment>
            )}
            <Text variant='legal' align='center' grey={80}>
              {text('product.premiumDisclosure')}
            </Text>
          </Content>
        </CenteredLayout>
      </CenteredLayoutWrapper>
    </ModalLayout>
  );
}
