import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useContext } from 'react';

import { IconName } from '@breathelife/types';
import { Text, Icon } from '@breathelife/ui-components';

import { Button } from '../../Components/Button';
import { CarrierContext } from '../../Context/CarrierContext';
import { CenteredLayout } from '../../Layouts/Centered/Layout';
import { text } from '../../Localization/Localizer';

export type Props = {
  title?: string;
  subtitle?: string;
  isSelfServeBlocked: boolean;
  showLoadingPage: boolean;
  onApplyThroughAdvisor: (productId?: string) => void;
};

export function FallbackQuoterView(props: Props): React.ReactElement {
  const { images } = useContext(CarrierContext);

  const { title: titleProp, subtitle: subtitleProp } = props;

  const title = titleProp || text('product.title');
  const subtitle = subtitleProp || text('product.subtitle');

  const offsetContent = _.get(images, 'planFinder.product') ? (
    <img src={_.get(images, 'planFinder.product')} alt='' />
  ) : (
    <Icon name={IconName.coverage} size='60px'></Icon>
  );

  return (
    <React.Fragment>
      <CenteredLayout
        hideProgress
        isLoading={props.showLoadingPage}
        loadingContent={{
          title: text('transitionLoadingPage.title'),
          subtitle: text('transitionLoadingPage.subtitle'),
          image: <Icon name={IconName.loadingCalculator} size='66px' />,
        }}
        offsetContent={offsetContent}
        title={title}
        subtitle={subtitle}
        headerVariant='default'
      >
        <Box my={3} px={2}>
          <Text>{text('product.otherProducts')}</Text>
        </Box>

        {!props.isSelfServeBlocked && (
          <Box my={3}>
            <Button
              data-testid='apply-through-advisor'
              variant='outlined'
              color='primary'
              size='large'
              fullWidth
              onClick={() => props.onApplyThroughAdvisor()}
            >
              {text('product.applyThroughAdvisorButtonLabel')}
            </Button>
          </Box>
        )}

        <Text variant='legal' align='center' grey={80}>
          {text('product.premiumDisclosure')}
        </Text>
      </CenteredLayout>
    </React.Fragment>
  );
}
