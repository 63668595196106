import { push as routerPush } from 'connected-react-router';
import React, { useContext } from 'react';

import { ResponsiveProps, withResponsive } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { ApplicationAssignee } from '../../Models/ApplicationAssignee';
import { ErrorPage } from './ErrorPage';

type ErrorCodes = '404' | '500';

type OwnProps = {
  errorCode: ErrorCodes;
};

export type ErrorPageProps = {
  applicationAssignee?: ApplicationAssignee;
  routerPush: (url: string) => void;
} & ResponsiveProps &
  OwnProps;

export function ErrorPageContainer(props: OwnProps): React.ReactElement | null {
  const { carrierCustomizations } = useContext(CarrierContext);

  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);

  const { ErrorPage: CarrierErrorPage } = carrierCustomizations;

  const ResponsiveErrorPage = withResponsive(CarrierErrorPage ?? ErrorPage);

  return <ResponsiveErrorPage applicationAssignee={applicationAssignee} routerPush={routerPush} {...props} />;
}
