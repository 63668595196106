import { LocalizedInsuranceProduct, QuotedInsuranceProduct } from '@breathelife/types';

import { LegacyProductInfo } from '../Models/Product';

type QuotedProduct = LegacyProductInfo | LocalizedInsuranceProduct;

const isNotLegacyInsuranceProduct = (e: QuotedProduct): e is QuotedInsuranceProduct => {
  return e['createdAt'] !== undefined;
};

export const sortQuotedProducts = (productA: QuotedProduct, productB: QuotedProduct): number => {
  const firstIndex = productA['index'] || Number.MAX_VALUE;
  const secondIndex = productB['index'] || Number.MAX_VALUE;

  const indexDelta = firstIndex - secondIndex;

  if (indexDelta !== 0) {
    return indexDelta;
  }

  if (isNotLegacyInsuranceProduct(productA) && isNotLegacyInsuranceProduct(productB)) {
    let productACreatedAt: string | Date = productA.createdAt;
    let productBCreatedAt: string | Date = productB.createdAt;

    if (typeof productACreatedAt === 'string') {
      productACreatedAt = new Date(productACreatedAt);
    }

    if (typeof productBCreatedAt === 'string') {
      productBCreatedAt = new Date(productBCreatedAt);
    }

    return productACreatedAt.getTime() - productBCreatedAt.getTime();
  }

  return 0;
};
