import React from 'react';
import styled from 'styled-components';

import { Loader } from '@breathelife/ui-components';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: flex;
`;

const Content = styled.div`
  margin: auto;
`;

export function Spinner(): React.ReactElement {
  return (
    <Container>
      <Content>
        <Loader size='5em' />
      </Content>
    </Container>
  );
}
