import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  getAllSubsections,
  isRenderingQuestionnaireComplete,
  OnAnswerChange,
  QuestionnaireEngine,
  RenderingQuestionnaire,
  RepeatedIndices,
} from '@breathelife/questionnaire-engine';
import { EngineEffects, RenderingType, Answers } from '@breathelife/types';

import { CarrierContext } from '../Context/CarrierContext';
import { shortLocale, text } from '../Localization/Localizer';
import { RenderingStep } from '../Models/Step';

type StepProps = {
  setLocalAnswers: React.Dispatch<React.SetStateAction<Answers>>;
  localAnswers: Answers;
  setSubmitFormRequested: React.Dispatch<React.SetStateAction<boolean>>;
  renderingQuestionnaire: RenderingQuestionnaire;
  renderingStep: RenderingStep;
  /**
   * Attempt to submit the answer for the current step.
   * Returns `true` if the submission is successful. `false` otherwise
   */
  onSubmit: () => boolean;
  onAnswerChange: OnAnswerChange;
};

export function useStep(
  questionnaireEngine: QuestionnaireEngine,
  displayErrors: boolean,
  submitAnswer: (answer: any) => void,
  answers: Answers,
  setShouldRefreshApplicationPremium?: (shouldRefreshApplicationPremium: boolean) => void
): StepProps {
  const { features } = useContext(CarrierContext);

  const [localAnswers, setLocalAnswers] = useState<Answers>(questionnaireEngine.getAnswersWithDefaultValues(answers));

  const [displayValidationErrors, setDisplayValidationErrors] = useState(displayErrors);
  const [submitFormRequested, setSubmitFormRequested] = useState(false);

  const language = shortLocale();

  const renderingQuestionnaire = questionnaireEngine.generateRenderingQuestionnaire(localAnswers, language, text, {
    renderingType: RenderingType.web,
    shouldValidateAllAnswers: displayValidationErrors,
  });

  const onSubmit = useCallback(() => {
    const renderingQuestionnaire = questionnaireEngine.generateRenderingQuestionnaire(localAnswers, language, text, {
      renderingType: RenderingType.web,
      shouldValidateAllAnswers: displayValidationErrors,
    });

    if (!isRenderingQuestionnaireComplete(renderingQuestionnaire)) {
      setDisplayValidationErrors(true);
      return false;
    }

    submitAnswer(localAnswers);
    return true;
  }, [localAnswers, language, questionnaireEngine, submitAnswer, displayValidationErrors, setDisplayValidationErrors]);

  // Handle delayed submit actions from callbacks that depend on state changes to localAnswers object
  useEffect(() => {
    if (!submitFormRequested) return;
    onSubmit();
    setSubmitFormRequested(false);
  }, [submitFormRequested, setSubmitFormRequested, onSubmit]);

  const allSteps: RenderingStep[] = getAllSubsections(renderingQuestionnaire);
  const renderingStep = _.first(allSteps);
  if (!renderingStep) throw new Error('Rendering step not found');

  const onAnswerChange = useCallback(
    (
      nodeId: string,
      answer: any,
      effects?: EngineEffects,
      repeatedIndices?: RepeatedIndices,
      triggerStepNavigation: boolean = false
    ): void => {
      setLocalAnswers((prevAnswers) => {
        const updatedAnswers = questionnaireEngine.updateAnswer(prevAnswers, nodeId, answer, effects, repeatedIndices);
        return updatedAnswers;
      });
      if (features.pricing?.enabled && !!setShouldRefreshApplicationPremium) {
        const isNodeIdAffectingPricing =
          _.isArray(features.pricing.nodeIdsAffectingPricing) &&
          (features.pricing.nodeIdsAffectingPricing as string[]).includes(nodeId);

        if (isNodeIdAffectingPricing) {
          setShouldRefreshApplicationPremium(true);
        }
      }

      if (triggerStepNavigation) {
        setSubmitFormRequested(true);
      }
    },
    [questionnaireEngine]
  );

  return {
    setLocalAnswers,
    localAnswers,
    setSubmitFormRequested,
    renderingQuestionnaire,
    renderingStep,
    onSubmit,
    onAnswerChange,
  };
}
