import _ from 'lodash';
import React, { useContext } from 'react';

import {
  getMonthlyPremiumFromApplication,
  getSelectedProductInformation,
} from 'Redux/InsuranceApplication/InsuranceApplicationSelectors';

import { PriceUpdateStep } from '../../Components/PriceUpdateStep/PriceUpdateStep';
import { CarrierContext } from '../../Context/CarrierContext';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { shortLocale } from '../../Localization/Localizer';

type Props = {
  onChangeAnswer: () => void;
  onContinue: () => void;
  useApplicationMonthlyPremium?: boolean;
  nextStepButtonText?: string;
  isNextBtnLoading: boolean;
};

export function PriceUpdateStepContainer(props: Props): React.ReactElement | null {
  const productInformation = useCxSelector((store) =>
    getSelectedProductInformation(store, { useApplicationMonthlyPremium: props.useApplicationMonthlyPremium })
  );

  const monthlyPremium = useCxSelector(getMonthlyPremiumFromApplication);

  const { images } = useContext(CarrierContext);
  const carrierLogo = _.get(images, `product.logo.${shortLocale()}`);

  if (!props.useApplicationMonthlyPremium && !productInformation) {
    return null;
  }

  return (
    <PriceUpdateStep
      productInformation={productInformation ?? undefined}
      monthlyPremium={monthlyPremium}
      carrierLogo={carrierLogo}
      onChangeAnswer={props.onChangeAnswer}
      onContinue={props.onContinue}
      nextStepButtonText={props.nextStepButtonText}
      isNextBtnLoading={props.isNextBtnLoading}
    />
  );
}
