import { replace as routerReplace } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import config from '@breathelife/config/frontend';

import { useCxSelector } from '../../Hooks/useCxSelector';
import Urls from '../../Navigation/Urls';
import { ContactConfirmationPage } from './ContactConfirmationPage';

export function ContactConfirmationPageContainer(): React.ReactElement {
  const dispatch = useDispatch();
  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);
  const basePath = useCxSelector((store) => store.consumerFlow.navigation.basePath);
  const hasAppliedThroughAdvisor = useCxSelector((store) => store.consumerFlow.communication.hasAppliedThroughAdvisor);
  const { cloudName, folder } = config.get('keys.cloudinary');

  useEffect(() => {
    if (!hasAppliedThroughAdvisor) {
      dispatch(routerReplace(Urls.fourOhFour(basePath)));
    }
  }, [basePath, dispatch, hasAppliedThroughAdvisor]);

  return (
    <ContactConfirmationPage
      applicationAssignee={applicationAssignee}
      cloudinaryProps={{
        cloudName,
        folder,
      }}
    />
  );
}
