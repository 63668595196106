import React from 'react';

import { getRepeatableSections } from '../../Helpers/products';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { LegacySelectedProductInformation } from '../../Models/Product';
import { getSelectedProductInformation } from '../../Redux/InsuranceApplication/InsuranceApplicationSelectors';
import { StepMetadata } from '../../types';
import { ProductSummary } from './ProductSummary';

type Props = {
  onBackButtonClick: () => void;
  stepMetadata?: StepMetadata;
  onNextClick: () => void;
  isNextBtnLoading: boolean;
  nextStepButtonText?: string;
};

export function ProductSummaryContainer(props: Props): React.ReactElement {
  const productInformation = useCxSelector(getSelectedProductInformation);
  if (!productInformation) throw new Error('Product information unavailable');

  return (
    <ProductSummary
      {...props}
      selectedProduct={productInformation}
      productSections={getRepeatableSections(productInformation as LegacySelectedProductInformation)}
    />
  );
}
