import { Box } from '@material-ui/core';
import React, { useContext } from 'react';

import { SelectedProductInformation } from '@breathelife/types';

import { CurrencyText } from '../../../Components/CurrencyText/CurrencyText';
import { CarrierContext } from '../../../Context/CarrierContext';
import { text } from '../../../Localization/Localizer';
import { LegacySelectedProductInformation } from '../../../Models/Product';
import { CarrierName, ContentContainer, LogoContainer, ProductText } from './Styles';

type Props = {
  carrierLogo: string;
  productInformation: LegacySelectedProductInformation | SelectedProductInformation;
};

export function ProductSummary(props: Props): React.ReactElement | null {
  const { enableLoadProductInformationFromDb } = useContext(CarrierContext);

  if (enableLoadProductInformationFromDb) {
    const product = props.productInformation as SelectedProductInformation;
    if (!product.premium || !product.coverageAmount) return null;
    return (
      <ContentContainer>
        <Box display='flex' mr={2}>
          <LogoContainer mr={2} alignItems='center' justifyContent='center'>
            <img alt='Company Logo' src={product.logoUrl} width='auto' height='36' />
          </LogoContainer>
          <Box display='flex' flexDirection='column' width='100%'>
            <CarrierName>{product.carrierName}</CarrierName>
            <ProductText>{product.name}</ProductText>
          </Box>
        </Box>
        <Box display='flex' alignItems='flex-end'>
          {<CurrencyText amount={product.premium} suffix={text('product.perMonth')} responsive />}
          <Box ml={2} overflow='hidden'>
            <CurrencyText amount={product.coverageAmount} suffix={text('product.coverage')} precision={0} responsive />
          </Box>
        </Box>
      </ContentContainer>
    );
  } else {
    const product = props.productInformation as LegacySelectedProductInformation;
    if (!product.premium || !product.coverageAmount) return null;
    return (
      <ContentContainer>
        <Box display='flex' mr={2}>
          <LogoContainer mr={2} alignItems='center' justifyContent='center'>
            <img alt='Company Logo' src={props.carrierLogo} width='auto' height='36' />
          </LogoContainer>
          <Box display='flex' flexDirection='column' width='100%'>
            <CarrierName>{text(`carriers.${product.carrierId}`)}</CarrierName>
            <ProductText>{product.shortName || product.name}</ProductText>
          </Box>
        </Box>
        <Box display='flex' alignItems='flex-end'>
          {<CurrencyText amount={product.premium} suffix={text('product.perMonth')} responsive />}
          <Box ml={2} overflow='hidden'>
            <CurrencyText amount={product.coverageAmount} suffix={text('product.coverage')} precision={0} responsive />
          </Box>
        </Box>
      </ContentContainer>
    );
  }
}
