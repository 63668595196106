import React from 'react';

import { FieldGenerator, RenderContext, StyleVariant } from '@breathelife/field-generator';
import { getAllQuestions, RenderingQuestion } from '@breathelife/questionnaire-engine';
import { NavigationButtons } from '@breathelife/ui-components';

import { Props as FormProps } from '../../Components/FormView/FormView';
import { text } from '../../Localization/Localizer';

type CustomVariantWrapperProps = {
  formViewProps: FormProps;
  children: React.ReactElement;
  useCustomNavigation?: boolean;
  customContentPosition?: 'top' | 'bottom';
  nextStepButtonText?: string;
};

export function CustomVariantWrapper(props: CustomVariantWrapperProps): React.ReactElement {
  const {
    renderingQuestionnaire,
    onSubmit,
    onAnswerComplete,
    onBackButtonClick,
    onInfoIconClick,
    onError,
    onAnswerChange,
    language,
    iconMap,
    stepMetadata,
    landingStepsIds,
    renderingStepId,
    nextStepButtonText,
  } = props.formViewProps;

  const questions = getAllQuestions(renderingQuestionnaire);
  const position = props.customContentPosition ?? 'bottom';

  return (
    <form onSubmit={onSubmit} noValidate={true}>
      {position === 'top' && props.children}
      {questions.map((question: RenderingQuestion) => (
        <FieldGenerator
          key={question.id}
          context={RenderContext.web}
          question={question}
          locale={language}
          onAnswerChange={onAnswerChange}
          onAnswerComplete={onAnswerComplete}
          styleVariant={StyleVariant.consumer}
          iconMap={iconMap}
          onInfoIconClick={onInfoIconClick}
          onError={onError}
        />
      ))}
      {position === 'bottom' && props.children}
      {!props.useCustomNavigation && (
        <NavigationButtons
          onPreviousClick={onBackButtonClick ? onBackButtonClick : () => {}}
          hidePrevious={!!landingStepsIds?.includes(renderingStepId)}
          nextButtonText={nextStepButtonText || stepMetadata?.continueButtonText || text('continue')}
          onNextClick={() => {}}
        />
      )}
    </form>
  );
}
