import React from 'react';
import { useDispatch } from 'react-redux';

import { useCxSelector } from '../../../Hooks/useCxSelector';
import Urls from '../../../Navigation/Urls';
import { communicationSlice } from '../../../Redux/Communication/CommunicationSlice';
import { navigate } from '../../../Redux/Navigation/NavigationOperations';
import { ContactModalContainer } from './ContactModalContainer';

export function ApplyThroughAdvisorModalContainer(): React.ReactElement {
  const dispatch = useDispatch();
  const basePath = useCxSelector((store) => store.consumerFlow.navigation.basePath);
  const closeOnSubmit = true;
  const onAfterSubmit = (): void => {
    dispatch(communicationSlice.actions.setHasAppliedThroughAdvisor(true));
    dispatch(navigate(Urls.contactConfirmation(basePath)));
  };
  return (
    <ContactModalContainer
      useMobileStylingOnDesktop={true}
      onAfterSubmit={onAfterSubmit}
      closeOnSubmit={closeOnSubmit}
    />
  );
}
