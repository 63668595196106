import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { navigationSlice } from '@breathelife/redux';
import { SchedulingTimeFrameConfig } from '@breathelife/ui-components';

import { GenericLinkLandingContainer } from '../Components/GenericLinkLanding/GenericLinkLandingContainer';
import { Modal } from '../Components/Modals';
import { Notification } from '../Components/Notification/Notification';
import { TitleHelmet } from '../Components/TitleHelmet/TitleHelmetContainer';
import { CarrierContext } from '../Context/CarrierContext';
import { useCxSelector } from '../Hooks/useCxSelector';
import Urls from '../Navigation/Urls';
import { ContactConfirmationPageContainer } from '../Pages/ContactConfirmationPage/ContactConfirmationPageContainer';
import { CreateMockApplicationView } from '../Pages/CreateMockApplicationPage/CreateMockApplicationView';
import { ErrorPageContainer } from '../Pages/ErrorPage/ErrorPageContainer';
import { ExternalApplicationAuthHandlerPage } from '../Pages/ExternalApplicationAuthPage/ExternalApplicationAuthHandler';
import { HomePageContainer } from '../Pages/HomePage/HomePageContainer';
import { SessionExpired } from '../Pages/SessionExpired/SessionExpired';
import { StepPageContainer } from '../Pages/StepPage/StepPageContainer';
import { configurationSlice } from '../Redux/Configuration/ConfigurationSlice';
import { stepSlice } from '../Redux/Step/StepSlice';
import { initializeApiServiceInterceptors, setAuthorizationInterceptor } from '../Services/ApiService';
import { StepIdMetadataMap } from '../types';

type RootProps = {
  basePath: string;
  lastStepsIds: string[];
  enableNewApplicationRoute: boolean;
  endOfFlowUrl: string;
  schedulingTimeFrames?: SchedulingTimeFrameConfig;
  stepIdMetadataMap?: StepIdMetadataMap;
};

function QuestionRoute(
  props: RouteComponentProps & { stepIdMetadataMap?: StepIdMetadataMap }
): React.ReactElement | null {
  const search = queryString.parse(props.location.search);
  const stepQuery = search.question as string | undefined;
  if (!stepQuery) return null;

  return <StepPageContainer stepId={stepQuery} stepIdMetadataMap={props.stepIdMetadataMap} />;
}

export function Root(props: RootProps): React.ReactElement | null {
  const [hasAddedAuthHeader, setHasAddedAuthHeader] = useState(false);
  const dispatch = useDispatch();

  const { carrierInfo, features } = useContext(CarrierContext);
  const hasExternalApplicationAuth = features.externalApplicationAuth.enabled;
  const applicationSessionJwtToken = useCxSelector((store) => store.consumerFlow.authentication.token);
  const hasAppliedThroughAdvisor = useCxSelector((store) => store.consumerFlow.communication.hasAppliedThroughAdvisor);
  const applicationId = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication?.id);

  const { canRestartAfterSessionExpired } = useContext(CarrierContext);

  const { basePath, lastStepsIds, endOfFlowUrl, schedulingTimeFrames, stepIdMetadataMap } = props;

  // Now that the `StepSlice` has been blacklisted from redux-persist, this makes sure to clear the StepSlice if it still lives
  // in the localeStorage (redux-persist) on mount because the last time the user opened the application, the StepSlice was still
  // saved in localeStorage and might contains wrong information like wrong nodeId.
  useEffect(() => {
    dispatch(stepSlice.actions.reset());
  }, [dispatch]);

  useEffect(() => {
    dispatch(navigationSlice.actions.setBasePath({ basePath }));
    dispatch(
      configurationSlice.actions.setConfigurations({
        lastStepsIds,
        endOfFlowUrl,
        schedulingTimeFrames,
      })
    );
    initializeApiServiceInterceptors({ 401: Urls.expired(basePath) });
  }, [dispatch, basePath, lastStepsIds, endOfFlowUrl, schedulingTimeFrames]);

  useEffect(() => {
    setHasAddedAuthHeader(false);

    if (applicationSessionJwtToken) {
      setAuthorizationInterceptor(applicationSessionJwtToken);
    }
    setHasAddedAuthHeader(true);
  }, [applicationSessionJwtToken]);

  // If we don't wait for the auth header to be set, on refresh, some API calls can occur before the token is set on the api service
  // Leading api calls to fail with a 401, and redirecting users to the /expired route
  // To prevent this issue, we make sure the useEffect above has finished running before rendering the rest of the module
  if (!hasAddedAuthHeader) return null;

  const canRenderConsumerFlowRoutes = !applicationId || !hasAppliedThroughAdvisor;
  const appliedThroughAdvisorRoutes = (
    <Switch>
      <Route exact path={Urls.home(basePath)} component={HomePageContainer} />
      <Route exact path={Urls.contactConfirmation(basePath)} component={ContactConfirmationPageContainer} />
      <Route path={Urls.fourOhFour(basePath)} render={() => <ErrorPageContainer errorCode='404' />} />
      <Route
        path={Urls.expired(basePath)}
        render={() => <SessionExpired canRestartAfterSessionExpired={canRestartAfterSessionExpired} />}
      />
      <Redirect to={Urls.contactConfirmation(basePath)} />
    </Switch>
  );

  const consumerFlowRoutes = (
    <Switch>
      <Route exact path={Urls.home(basePath)} component={() => <HomePageContainer />} />
      <Route
        exact
        path={Urls.questions(basePath)}
        render={(routeProps) => <QuestionRoute {...routeProps} stepIdMetadataMap={stepIdMetadataMap} />}
      />
      <Route exact path={Urls.genericLinkLanding(basePath)} render={() => <GenericLinkLandingContainer />} />
      <Route exact path={Urls.contactConfirmation(basePath)} component={ContactConfirmationPageContainer} />
      <Route exact path={Urls.error(basePath)} render={() => <ErrorPageContainer errorCode='500' />} />
      {props.enableNewApplicationRoute && (
        <Route path={Urls.new(basePath)} render={() => <CreateMockApplicationView basePath={basePath} />} />
      )}
      {hasExternalApplicationAuth && <Route path={Urls.external()} component={ExternalApplicationAuthHandlerPage} />}
      <Route path={Urls.fourOhFour(basePath)} render={() => <ErrorPageContainer errorCode='404' />} />
      <Route
        path={Urls.expired(basePath)}
        render={() => <SessionExpired canRestartAfterSessionExpired={canRestartAfterSessionExpired} />}
      />
      <Redirect to={Urls.fourOhFour(basePath)} />
    </Switch>
  );

  return (
    <React.Fragment>
      <TitleHelmet>
        <meta name='description' content={carrierInfo.indexPageTitle} />
      </TitleHelmet>
      <Notification />
      <Modal />
      {canRenderConsumerFlowRoutes ? consumerFlowRoutes : appliedThroughAdvisorRoutes}
    </React.Fragment>
  );
}
