import * as PaymentService from '../../Services/PaymentService';
import { Dispatch } from '../Store';
import { paymentSlice } from './PaymentSlice';

const { actions } = paymentSlice;

export const createSetupIntent =
  (insuranceApplicationId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setIsLoading(true));

    const { clientSecret } = await PaymentService.createStripeSetupIntent(insuranceApplicationId);

    dispatch(actions.setClientSecret(clientSecret ?? ''));

    dispatch(actions.setIsLoading(false));
  };

export const fetchClientSecret =
  (insuranceApplicationId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setIsLoading(true));

    try {
      const { clientSecret } = await PaymentService.retrieveStripeSetupIntent(insuranceApplicationId);
      dispatch(actions.setClientSecret(clientSecret ?? ''));
    } catch (err) {
      if (err.response?.status === 404) {
        // If we couldn't retrieve a setup intent due to 404 (Not Found), we attempt to re-create a new one
        return await dispatch(createSetupIntent(insuranceApplicationId));
      }
      throw err;
    } finally {
      dispatch(actions.setIsLoading(false));
    }
  };
