import axios from 'axios';

import config from '@breathelife/config/frontend';
import {
  addErrorRedirectInterceptor,
  addJwtIntoHeaderInterceptor,
  addProductIntoHeaderInterceptor,
  addTraceIntoHeaderInterceptor,
  Gateway,
  Redirects,
  sentryInterceptor,
} from '@breathelife/gateway';

import { getTraceId } from '../Helpers/monitoring';

const ApiService = axios.create();
const traceId = getTraceId();

export function initializeApiServiceInterceptors(errorRedirects: Redirects): void {
  // TODO: DEV-3296 use ProductName.ConsumerFlow from @direct/frontend-common/dist/Monitoring
  ApiService.interceptors.request.use(addProductIntoHeaderInterceptor('consumer-flow'));
  ApiService.interceptors.request.use(addTraceIntoHeaderInterceptor(traceId));
  ApiService.interceptors.response.use((response) => response, addErrorRedirectInterceptor(errorRedirects));
  ApiService.interceptors.response.use((response) => response, sentryInterceptor);
}

let authorizationInterceptorIndex: number;
// Add header authorization token
export function setAuthorizationInterceptor(token?: string): void {
  if (typeof authorizationInterceptorIndex !== 'undefined') {
    ApiService.interceptors.request.eject(authorizationInterceptorIndex);
  }

  authorizationInterceptorIndex = ApiService.interceptors.request.use(addJwtIntoHeaderInterceptor(token));
}

const releaseVersion = config.get<string>('git.commitSha');

export default new Gateway(releaseVersion, ApiService);
