import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { deserializeNodeIdToAnswerPathMap, QuestionnaireEngine } from '@breathelife/questionnaire-engine';
import { ButtonName, TypewriterTracking } from '@breathelife/react-tracking';
import { DEFAULT_TIMEZONE_NAME, LeadMarketingMetadata, Timezone } from '@breathelife/types';

import { createQuestionnaireFromSubsection } from '../../Helpers/createQuestionnaireFromSubsection';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { shortLocale } from '../../Localization/Localizer';
import { StepProps } from '../../Models/Question';
import { startGenericFlow as startFlowOperation } from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import * as stepOperations from '../../Redux/Step/StepOperations';
import { GenericLinkLanding } from './GenericLinkLanding';

export function GenericLinkLandingContainer(): React.ReactElement | null {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const lang = shortLocale();

  useEffect(() => {
    dispatch(stepOperations.getLandingStep());
  }, [dispatch]);

  const { currentStep, nodeIdToAnswerPathMap } = useCxSelector((store) => store.consumerFlow.step);
  const isApplicationLoading = useCxSelector((store) => store.consumerFlow.insuranceApplication.isLoading);

  const questionnaireEngine = useMemo(() => {
    if (!nodeIdToAnswerPathMap || !currentStep) return;
    const timezoneResult = Timezone.from(Intl.DateTimeFormat().resolvedOptions().timeZone).orElse(() =>
      Timezone.from(DEFAULT_TIMEZONE_NAME)
    );

    if (timezoneResult.isError()) {
      throw new Error('Could not create the timezone for the application or using the default one.');
    }

    const deserializedNodeIdToAnswerPathMap = deserializeNodeIdToAnswerPathMap(nodeIdToAnswerPathMap);
    const questionnaire = createQuestionnaireFromSubsection(currentStep);
    return new QuestionnaireEngine(
      questionnaire,
      deserializedNodeIdToAnswerPathMap,
      {
        scopes: [],
      },
      undefined,
      timezoneResult.value
    );
  }, [nodeIdToAnswerPathMap, currentStep]);

  const marketingMetadata: LeadMarketingMetadata | undefined = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const utmId = searchParams.get('utm_id');
    const utmSource = searchParams.get('utm_source');

    return utmId && utmSource ? { utmId, utmSource } : undefined;
  }, [search]);

  const submitAnswer = useCallback(
    async (answer: any): Promise<void> => {
      if (!currentStep) return;
      dispatch(
        startFlowOperation({
          stepId: currentStep?.id,
          answers: answer,
          lang,
          marketingMetadata,
        })
      );
    },
    [dispatch, currentStep, lang, marketingMetadata]
  );

  const onAnswerComplete = useCallback((fieldId: string) => {
    TypewriterTracking.completedField({
      fieldId,
      hashedId: null,
    });
  }, []);

  const onError = useCallback((fieldId: string, error?: string) => {
    TypewriterTracking.errorOccurred({
      hashedId: null,
      error: `Field ${fieldId} error: ${error}`,
    });
  }, []);

  const onInfoIconClick = useCallback(() => {
    TypewriterTracking.clickedButton({
      hashedId: null,
      buttonName: ButtonName.moreInformation,
    });
  }, []);

  if (!currentStep || !questionnaireEngine) return null;

  const questionProps: StepProps = {
    step: currentStep || {},
    submitAnswer,
    isLoading: isApplicationLoading,
    answers: {},
    onAnswerComplete,
    onInfoIconClick,
    onError,
  };

  return <GenericLinkLanding {...questionProps} questionnaireEngine={questionnaireEngine} />;
}
