import { Language } from '@breathelife/types';

import { BaseApiResponse, BaseQuestionResponse } from '../Models/QuestionResponse';
import { Step } from '../Models/Step';
import ApiService from './ApiService';

const consumerApiService = ApiService.consumer;

type ApiResponse = BaseApiResponse & {
  step: Step;
};

export type QuestionResponse = BaseQuestionResponse & {
  step: Step;
};

export async function createApplication(data?: unknown): Promise<QuestionResponse | { id: string }> {
  const applicationResponse = await consumerApiService.createApplication(data);
  return applicationResponse.data;
}

export async function updateApplication(
  applicationId: string,
  data: { lang?: Language; premium?: number | null }
): Promise<QuestionResponse> {
  const applicationResponse = await consumerApiService.updateApplication<QuestionResponse>(applicationId, data);
  return applicationResponse.data;
}

export async function fetchApplication(insuranceApplicationId: string): Promise<QuestionResponse> {
  const applicationResponse = await consumerApiService.fetchApplication(insuranceApplicationId);
  return applicationResponse.data;
}

export async function getQuestion(applicationId: string, questionId: string): Promise<ApiResponse> {
  const questionResponse = await consumerApiService.fetchQuestion(applicationId, questionId);
  return questionResponse.data;
}

export async function getPreviousQuestionData(applicationId: string, currentQuestionId: string): Promise<ApiResponse> {
  const prevQuestionResponse = await consumerApiService.getPreviousQuestionData(applicationId, currentQuestionId);
  return prevQuestionResponse.data;
}

export async function sendQuestionAnswer(
  applicationId: string,
  questionId: string,
  answerData: unknown
): Promise<ApiResponse> {
  const answerResponse = await consumerApiService.sendQuestionAnswer(applicationId, questionId, answerData);
  return answerResponse.data;
}
