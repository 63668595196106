import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 20px;
  @media (min-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    align-items: center;
    padding-top: 96px;
    max-width: 552px;
    margin: auto;
  }
`;

export const Title = styled.p`
  color: ${(props) => props.theme.colors.grey[100]};
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  margin: 24px 0 16px;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    text-align: center;
  }
`;

export const Subtitle = styled.p`
  color: ${(props) => props.theme.colors.grey[90]};
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 22px;
  text-align: left;
  margin-bottom: 16px;
  a {
    color: ${(props) => props.theme.colors.primary[60]};
  }
  span {
    white-space: nowrap;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    text-align: center;
  }
`;

export const StyledSubtitleHeader = styled.div`
  font-size: 17px;
  line-height: 29px;
  max-width: 90%;
  margin-bottom: 4px;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile.min}) {
    margin-bottom: 20px;
    font-size: 24px;
  }
`;

export const StyledSubtitle = styled.div`
  font-size: 12px;
  line-height: 20px;
  max-width: 90%;
  padding-bottom: 25px;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile.min}) {
    font-size: 16px;
  }
`;

export const ConfirmationSignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.grey[10]};
  border: 1px solid ${(props) => props.theme.colors.grey[30]};
  border-radius: 4px;
  min-height: 88px;
  padding: 24px;
  margin-bottom: 16px;
`;

export const CryptoSignatureTitle = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fonts.weight.heavy};
  text-align: left;
  line-height: 16px;
  margin-bottom: 8px;
  @media (min-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    text-align: center;
  }
`;

export const CryptoSignature = styled(CryptoSignatureTitle)`
  font-size: 14px;
  line-height: 18px;
  line-break: loose;
  overflow-wrap: break-word;
  word-break: break-all;
  margin-bottom: 8px;
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  color: ${(props) => props.theme.colors.grey[90]};
`;
