import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import config from '@breathelife/config/frontend';
import { hash } from '@breathelife/hash';
import { InsuranceApplicationStepId } from '@breathelife/insurance-form-builder';
import {
  areAnyFieldsAnsweredWithinSubsectionScope,
  deserializeNodeIdToAnswerPathMap,
  getAllAnswersWithinSubsectionScope,
  QuestionnaireEngine,
  SerializedNodeIdToAnswerPathMap,
} from '@breathelife/questionnaire-engine';
import { ButtonName, TypewriterTracking } from '@breathelife/react-tracking';
import { setA11yMessage } from '@breathelife/redux';
import { Answers, DEFAULT_TIMEZONE_NAME, Timezone } from '@breathelife/types';
import { GoogleMapsHelmet, PopupContext } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { createQuestionnaireFromSubsection } from '../../Helpers/createQuestionnaireFromSubsection';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { a11yNavigationPageTitle, text } from '../../Localization/Localizer';
import { StepProps } from '../../Models/Question';
import { refreshApplicationPremium } from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import * as stepOperations from '../../Redux/Step/StepOperations';
import { StepIdMetadataMap } from '../../types';
import { StepPage } from './StepPage';

type Props = {
  stepId?: string;
  stepIdMetadataMap?: StepIdMetadataMap;
};

// TODO: Replace this check with an interface with the questionnaire that uses node ids.
// This is a quick and dirty way of triggering the quote fetching for the price update step.
const PREMIUM_UPDATE_SUBSECTIONS = [InsuranceApplicationStepId.lifestyleTobaccoUse];

export function StepPageContainer(props: Props): React.ReactElement | null {
  const { stepId, stepIdMetadataMap } = props;

  const dispatch = useDispatch();

  const { popup } = useContext(PopupContext);
  const { layout, theme } = useContext(CarrierContext);

  const stepState = useCxSelector((store) => store.consumerFlow.step);
  const { currentStep, hasErrors, isLoading } = stepState;

  const { landingStepsIds } = useCxSelector((store) => store.consumerFlow.configuration);
  const shouldShowLoadingPage = useCxSelector((store) => store.consumerFlow.navigation.loadingPage.isVisible);
  const progress = useCxSelector((store) => store.consumerFlow.progress.progress);
  const insuranceApplication = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication);

  const [shouldRefreshApplicationPremium, setShouldRefreshApplicationPremium] = useState<boolean>(false);
  const currentStepId = currentStep?.id;
  const applicationId = insuranceApplication?.id ?? null;
  const answers = insuranceApplication?.answers as Answers;
  const nodeIdToAnswerPathMap = deserializeNodeIdToAnswerPathMap(
    insuranceApplication?.answersDataStructure as SerializedNodeIdToAnswerPathMap
  );

  const googleMapsApiKey = config.get<string | undefined>('keys.googleMapsPlaces');

  const questionnaire = createQuestionnaireFromSubsection(currentStep);
  const questionTitle = text(`a11y.questionTitle.${currentStep?.id}`);
  const pageTitle = `${questionTitle} - ${text('a11y.pageTitle.apply')}`;

  useEffect(() => {
    if (!applicationId || !currentStepId) return;
    TypewriterTracking.viewedStep({
      stepId: currentStepId ?? '',
      hashedId: hash(applicationId),
    });
  }, [applicationId, currentStepId]);

  useEffect(() => {
    if (!stepId) return;
    dispatch(stepOperations.setCurrentQuestionById(stepId));
  }, [dispatch, stepId]);

  const getHasAnswersChanged = useCallback(
    (stepId: string, newAnswers: Answers): boolean => {
      const previousScopedAnswers = getAllAnswersWithinSubsectionScope(
        questionnaire,
        stepId,
        answers,
        nodeIdToAnswerPathMap
      );
      const newScopedAnswers = getAllAnswersWithinSubsectionScope(
        questionnaire,
        stepId,
        newAnswers,
        nodeIdToAnswerPathMap
      );
      const anyFieldsWerePreviouslyAnswered = areAnyFieldsAnsweredWithinSubsectionScope(
        questionnaire,
        stepId,
        answers,
        nodeIdToAnswerPathMap
      );

      const hasAnswerChanged = anyFieldsWerePreviouslyAnswered && !_.isEqual(newScopedAnswers, previousScopedAnswers);
      return hasAnswerChanged;
    },
    [answers, questionnaire, nodeIdToAnswerPathMap]
  );

  const submitAnswer = useCallback(
    async (answer: any): Promise<void> => {
      if (!applicationId || !currentStep || !props.stepId) return;

      const hasAnswerChanged = getHasAnswersChanged(currentStep.id, answer);

      TypewriterTracking.completedStep({
        hasChanged: hasAnswerChanged,
        hashedId: hash(applicationId),
        insuredPersonIndex: 0,
        stepId: currentStep.id,
      });

      await dispatch(stepOperations.answerQuestionAndNavigate(stepId as string, answer));

      const isPremiumUpdateStep = PREMIUM_UPDATE_SUBSECTIONS.includes(currentStep.id as InsuranceApplicationStepId);

      if (isPremiumUpdateStep || shouldRefreshApplicationPremium) {
        dispatch(refreshApplicationPremium());

        setShouldRefreshApplicationPremium(false);
      }
    },
    [applicationId, currentStep, props.stepId, getHasAnswersChanged, dispatch, stepId, shouldRefreshApplicationPremium]
  );

  const onAnswerComplete = useCallback(
    (fieldId: string) => {
      TypewriterTracking.completedField({
        fieldId,
        hashedId: hash(applicationId),
      });
    },
    [applicationId]
  );

  const onInfoIconClick = useCallback(() => {
    TypewriterTracking.clickedButton({
      hashedId: hash(applicationId),
      buttonName: ButtonName.moreInformation,
    });
  }, [applicationId]);

  const onFieldError = useCallback(
    (fieldId: string, error?: string) => {
      TypewriterTracking.errorOccurred({
        hashedId: hash(applicationId),
        error: `Field ${fieldId} error: ${error}`,
      });
    },
    [applicationId]
  );

  const navigateToPreviousQuestion = useCallback((): void => {
    dispatch(stepOperations.navigateToPreviousQuestion(stepId as string));
  }, [dispatch, stepId]);

  if (!currentStep || !stepId) return null;

  const questionProps: StepProps = {
    step: currentStep || {},
    submitAnswer,
    isLoading,
    answers: insuranceApplication?.answers as Answers,
    onAnswerComplete,
    onInfoIconClick,
    onError: onFieldError,
  };

  const timezoneResult = Timezone.from(Intl.DateTimeFormat().resolvedOptions().timeZone).orElse(() =>
    Timezone.from(DEFAULT_TIMEZONE_NAME)
  );

  if (timezoneResult.isError()) {
    throw new Error('Could not create the timezone for the application or using the default one.');
  }

  const questionnaireEngine = new QuestionnaireEngine(
    questionnaire,
    nodeIdToAnswerPathMap,
    {
      scopes: insuranceApplication?.insuranceScopes ?? [],
    },
    undefined,
    timezoneResult.value
  );

  dispatch(setA11yMessage(a11yNavigationPageTitle(pageTitle)));
  return (
    <React.Fragment>
      {googleMapsApiKey && <GoogleMapsHelmet googleMapsApiKey={googleMapsApiKey} />}
      <StepPage
        {...questionProps}
        key={questionProps.step.id}
        displayErrors={hasErrors}
        progress={progress}
        popup={popup}
        isLoading={isLoading}
        showLoadingPage={shouldShowLoadingPage}
        onBackButtonClick={navigateToPreviousQuestion}
        layout={layout}
        theme={theme}
        landingStepsIds={landingStepsIds}
        questionnaireEngine={questionnaireEngine}
        nodeIdToAnswerPathMap={nodeIdToAnswerPathMap}
        stepMetadata={stepIdMetadataMap?.[stepId] ?? {}}
        setShouldRefreshApplicationPremium={setShouldRefreshApplicationPremium}
      />
    </React.Fragment>
  );
}
