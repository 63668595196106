import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  deserializeNodeIdToAnswerPathMap,
  getAllSubsections,
  NodeIdToAnswerPathMap,
  Questionnaire,
  QuestionnaireEngine,
  RenderingQuestionnaire,
  SerializedNodeIdToAnswerPathMap,
} from '@breathelife/questionnaire-engine';
import { DEFAULT_TIMEZONE_NAME, RenderingType, Timezone } from '@breathelife/types';
import { DebugToolbarModal, Loader } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { shortLocale, text } from '../../Localization/Localizer';
import { updateAnswersUntil } from '../../Redux/DebugToolbar/DebugToolbarOperations';
import { navigateToQuestion } from '../../Redux/Navigation/NavigationOperations';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import ApiService from '../../Services/ApiService';

// TODO: move to shared/types
export type QuestionnaireResponse = {
  version: string;
  questionnaire: Questionnaire;
  nodeIdToAnswerPath?: SerializedNodeIdToAnswerPathMap;
  isLegacyQuestionnaire?: boolean;
};

export enum DebugView {
  stepList = 'stepList',
  stepFill = 'stepFill',
}

type Props = {
  debugView: DebugView;
  onClose: () => void;
};

export function DebugToolbarModalContainer(props: Props): React.ReactElement {
  const { debugView, onClose } = props;
  const dispatch = useDispatch();

  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [renderingQuestionnaire, setRenderingQuestionnaire] = useState<RenderingQuestionnaire>();
  const [currentQuestionnaireApplicationId, setCurrentQuestionnaireApplicationId] = useState<string>();
  const [nodeIdToAnswerPathMap, setNodeIdToAnswerPathMap] = useState<NodeIdToAnswerPathMap>();
  const [isFetchingQuestionnaire, setIsFetchingQuestionnaire] = useState(false);
  const applicationId = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication?.id);

  useEffect(() => {
    const isSameApplicationId = currentQuestionnaireApplicationId === applicationId;
    if (!applicationId || isSameApplicationId || isFetchingQuestionnaire) return;

    async function fetchQuestionnaire(): Promise<void> {
      setIsFetchingQuestionnaire(true);

      try {
        const response = await ApiService.getQuestionnaire<QuestionnaireResponse>(applicationId);
        const { questionnaire, nodeIdToAnswerPath: serializedNodeIdToAnswerPathMap } = response.data;
        if (!questionnaire || !serializedNodeIdToAnswerPathMap) {
          throw new Error('Missing questionnaire or nodeIdToAnswerPath in the response');
        }

        setCurrentQuestionnaireApplicationId(applicationId);
        setQuestionnaire(questionnaire);
        setNodeIdToAnswerPathMap(deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap));

        const nodeIdToAnswerPathMap = deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap);

        const timezoneResult = Timezone.from(Intl.DateTimeFormat().resolvedOptions().timeZone).orElse(() =>
          Timezone.from(DEFAULT_TIMEZONE_NAME)
        );

        if (timezoneResult.isError()) {
          throw new Error('Could not determine the timezone of the user.');
        }

        setRenderingQuestionnaire(
          buildRenderingQuestionnaire(questionnaire, nodeIdToAnswerPathMap, timezoneResult.value)
        );
      } catch (err) {
        // No need to translate since this is only for internal use.
        dispatch(
          notificationSlice.actions.setError({
            message: 'An error occurred while fetching the questionnaire for the debug toolbar',
          })
        );
      } finally {
        setIsFetchingQuestionnaire(false);
      }
    }
    void fetchQuestionnaire();
  }, [
    dispatch,
    questionnaire,
    nodeIdToAnswerPathMap,
    isFetchingQuestionnaire,
    applicationId,
    currentQuestionnaireApplicationId,
  ]);

  const steps = useMemo(() => {
    if (!renderingQuestionnaire) return;
    // TODO: Handle steps that are not visible
    const subsectionsById = getAllSubsections(renderingQuestionnaire).map((subsection) => subsection.id);
    return subsectionsById;
  }, [renderingQuestionnaire]);

  const onNavigate = useCallback(
    (stepId) => {
      dispatch(navigateToQuestion(stepId));
    },
    [dispatch]
  );

  const onPrefillApplication = useCallback(
    (stepId: string) => {
      if (!applicationId) return;
      dispatch(updateAnswersUntil(applicationId, stepId));
      onClose();
    },
    [applicationId, dispatch, onClose]
  );

  const onStepSelect = useCallback(
    (stepId) => {
      debugView === DebugView.stepFill ? onPrefillApplication(stepId) : onNavigate(stepId);
      onClose();
    },
    [debugView, onPrefillApplication, onNavigate, onClose]
  );

  if (isFetchingQuestionnaire) return <Loader />;

  return (
    <DebugToolbarModal
      onClose={onClose}
      steps={steps ?? []}
      onStepSelect={onStepSelect}
      title={debugView === DebugView.stepFill ? 'Prefill the application' : 'Jump to step'}
    />
  );
}

function buildRenderingQuestionnaire(
  questionnaire: Questionnaire,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  timezone: Timezone
): RenderingQuestionnaire {
  const language = shortLocale();
  const questionnaireEngine = new QuestionnaireEngine(questionnaire, nodeIdToAnswerPathMap, {}, undefined, timezone);

  const renderingQuestionnaire = questionnaireEngine.generateRenderingQuestionnaire({}, language, text, {
    // TODO: Remove filter
    renderingType: RenderingType.consumerSummary,
    shouldValidateAllAnswers: false,
  });

  return renderingQuestionnaire;
}
