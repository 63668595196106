import { getDefaultMiddleware, ThunkDispatch } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { AnyAction, combineReducers, Middleware } from 'redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, Transform } from 'redux-persist';
import { createWhitelistFilter } from 'redux-persist-transform-filter';

import {
  authenticationSlice,
  AuthenticationState,
  errorSlice,
  ErrorState,
  navigationSlice,
  NavigationState,
  progressSlice,
  ProgressState,
} from '@breathelife/redux';

import { applicationAssigneeSlice, ApplicationAssigneeState } from './ApplicationAssignee/ApplicationAssigneeSlice';
import { communicationSlice, CommunicationState } from './Communication/CommunicationSlice';
import { configurationSlice, ConfigurationState } from './Configuration/ConfigurationSlice';
import { insuranceApplicationSlice, InsuranceApplicationState } from './InsuranceApplication/InsuranceApplicationSlice';
import { layoutSlice, LayoutState } from './Layout/LayoutSlice';
import { notificationSlice, NotificationState } from './Notification/NotificationSlice';
import { paymentSlice, PaymentState } from './Payment/PaymentSlice';
import { settingsSlice, SettingsState } from './Settings/SettingsSlice';
import { stepSlice, StepState } from './Step/StepSlice';
import { submissionSlice, SubmissionState } from './Submission/SubmissionSlice';
import { themeSlice, ThemeState } from './Theme/ThemeSlice';

export type ConsumerFlowStore = {
  consumerFlow: {
    notification: NotificationState;
    layout: LayoutState;
    insuranceApplication: InsuranceApplicationState;
    applicationAssignee: ApplicationAssigneeState;
    communication: CommunicationState;
    submission: SubmissionState;
    configuration: ConfigurationState;
    step: StepState;
    navigation: NavigationState;
    progress: ProgressState;
    error: ErrorState;
    authentication: AuthenticationState;
    theme: ThemeState;
    settings: SettingsState;
    payment: PaymentState;
  };
};

export type Dispatch = ThunkDispatch<ConsumerFlowStore, unknown, AnyAction>;

const RootReducer = {
  consumerFlow: combineReducers({
    notification: notificationSlice.reducer,
    layout: layoutSlice.reducer,
    insuranceApplication: insuranceApplicationSlice.reducer,
    applicationAssignee: applicationAssigneeSlice.reducer,
    communication: communicationSlice.reducer,
    submission: submissionSlice.reducer,
    configuration: configurationSlice.reducer,
    step: stepSlice.reducer,
    navigation: navigationSlice.reducer,
    progress: progressSlice.reducer,
    error: errorSlice.reducer,
    authentication: authenticationSlice.reducer,
    theme: themeSlice.reducer,
    settings: settingsSlice.reducer,
    payment: paymentSlice.reducer,
  }),
};

const createMiddlewares = (history: History): Middleware[] => [
  routerMiddleware(history),
  ...getDefaultMiddleware({
    serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
    // CreateSlice is using Immerjs by default. We don't need the immutable check since all of are reducer are
    // using CreateSlice
    immutableCheck: false,
  }),
];

const persistenceTransforms: Transform<unknown, unknown>[] = [
  createWhitelistFilter('consumerFlow', [
    'layout',
    'submission',
    'configuration',
    'navigation',
    'progress',
    'error',
    'authentication',
    'theme',
    'settings',

    // Listing all of insuranceApplication property except isLoading
    'insuranceApplication.insuranceApplication',
    'insuranceApplication.recommendedCoverage',
    'insuranceApplication.products',
    'insuranceApplication.quotes',
    'insuranceApplication.addonsMetadata',
    'insuranceApplication.referencePremium',

    // Listing all of applicationAssignee property except isLoading
    'applicationAssignee.assignee',
  ]),
];

export const consumerFlowStore = {
  RootReducer,
  persistenceTransforms,
  middlewares: (history: History) => createMiddlewares(history),
};
