import React, { useContext } from 'react';

import { TransactionFlowSidebar as Sidebar } from '@breathelife/ui-components';

import { CarrierLogo } from '../../Components/CarrierLogo/CarrierLogo';
import { CarrierContext } from '../../Context/CarrierContext';

type Props = {
  title: string;
};

export function TransactionFlowSidebar(props: Props): React.ReactElement {
  const { carrierCustomizations } = useContext(CarrierContext);
  const { LeftPanelInfoView } = carrierCustomizations;

  return (
    <Sidebar title={props.title} logo={<CarrierLogo />}>
      <LeftPanelInfoView />
    </Sidebar>
  );
}
