import Box from '@material-ui/core/Box';
import HtmlReactParser from 'html-react-parser';
import React from 'react';

import { IconName } from '@breathelife/types';
import { Text, Title, Icon } from '@breathelife/ui-components';

import { text } from '../../../Localization/Localizer';
import { Content, PolicyDetailsContainer } from './Styles';

type PolicyDetailsProps = {
  url: string;
};

export function PolicyDetails(props: PolicyDetailsProps): React.ReactElement | null {
  const { url } = props;

  if (!url) return null;

  return (
    <PolicyDetailsContainer py={3}>
      <Content px={3}>
        <Box mb={2}>
          <Title level={2} variant={4}>
            {text('product.policyDetails.title')}
          </Title>
        </Box>
        <Box display='flex' alignItems='center'>
          <Box lineHeight='0px' mx={1.5}>
            <Icon name={IconName.fileDownload} size='36px' />
          </Box>
          <Text variant='small-copy' grey={80}>
            {HtmlReactParser(text('product.policyDetails.link', { url: url }))}
          </Text>
        </Box>
      </Content>
    </PolicyDetailsContainer>
  );
}
