import React from 'react';
import { useTheme } from 'styled-components';

import { SharedTheme } from '@breathelife/ui-components';

import { text, toCurrency } from '../../Localization/Localizer';
import { MonthText, PricingNumberText, PricingText, SmallContent } from './Styles';

type Props = {
  revisedPremium: number;
  hasPremiumChanged: boolean;
};

export function PremiumChangeView(props: Props): React.ReactElement | null {
  const { revisedPremium, hasPremiumChanged } = props;

  const theme = useTheme() as SharedTheme;

  if (!hasPremiumChanged) {
    return null;
  }

  return (
    <SmallContent>
      <PricingText>{text('product.addons.updatedPremium.description')}</PricingText>
      <PricingNumberText color={theme.colors.secondary.default}>
        {toCurrency(revisedPremium)}
        <MonthText>{text('product.perMonth')}</MonthText>
      </PricingNumberText>
    </SmallContent>
  );
}
