import React, { useContext } from 'react';

import { FaviconLinks, TitleHelmet as SharedTitleHelmet } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';

type Props = {
  title?: string;
  text?: string;
  children?: React.ReactNode;
};

export function TitleHelmet(props: Props): React.ReactElement {
  const { carrierInfo, cloudinaryConfig } = useContext(CarrierContext);

  return (
    <React.Fragment>
      <SharedTitleHelmet title={props.title ?? carrierInfo.indexPageTitle} {...props} />
      {carrierInfo.logoCompact && cloudinaryConfig && (
        <FaviconLinks compactLogoUrl={carrierInfo.logoCompact} cloudinaryConfig={cloudinaryConfig} />
      )}
    </React.Fragment>
  );
}
