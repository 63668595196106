import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicationAssignee } from '../../Models/ApplicationAssignee';

const defaultState = {
  isLoading: false,
};

export type ApplicationAssigneeState = {
  assignee?: ApplicationAssigneePayload;
  isLoading: boolean;
};

type ApplicationAssigneePayload = ApplicationAssignee;

type ActionsType = {
  setApplicationAssignee: (
    state: ApplicationAssigneeState,
    { payload }: PayloadAction<ApplicationAssigneePayload>
  ) => void;
  setIsLoading: (state: ApplicationAssigneeState, { payload }: PayloadAction<boolean>) => void;
};

export const applicationAssigneeSlice = createSlice<ApplicationAssigneeState, ActionsType>({
  name: 'consumerFlow/applicationAssignee',
  initialState: defaultState,
  reducers: {
    setApplicationAssignee(state: ApplicationAssigneeState, { payload }: PayloadAction<ApplicationAssigneePayload>) {
      state.assignee = payload;
    },
    setIsLoading(state: ApplicationAssigneeState, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
  },
});
