import Box from '@material-ui/core/Box';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import {
  ResponsiveProps,
  TransactionFlowLayout as TransactionFlowBaseLayout,
  withResponsive,
} from '@breathelife/ui-components';

import { getLegalItems, text } from '../../Localization/Localizer';
import { ClientInfo, LegacyProductInfo } from '../../Models/ApplicationState';
import { TransactionFlowHeader } from './Header';
import { TransactionFlowSidebar } from './Sidebar';

const Container = styled(Box)`
  width: 100%;
  max-width: 580px;
  font-family: unset;
  margin-top: unset;
  margin: 0 auto;
`;

type Props = {
  title: string;
  questionId: string;
  progress: number;
  popup: React.ReactElement | null;
  isLoading: boolean;
  onBackButtonClick: () => void;
  client?: ClientInfo;
  product?: LegacyProductInfo;
  showMobileTitle?: boolean;
  showScheduleCallAction?: boolean;
  children: React.ReactNode;
  useEngine?: boolean;
  isFullWidthLayout?: boolean;
  hideHeaderOnDesktop?: boolean;
} & ResponsiveProps;

function TransactionFlowLayoutWithResponsive(props: Props): React.ReactElement {
  const {
    isLoading,
    product,
    client,
    children,
    hideHeaderOnDesktop,
    showMobileTitle = true,
    showScheduleCallAction = true,
  } = props;

  const [isMobileHeaderCollapsed, collapseMobileHeader] = useState<boolean>(false);

  const handleScroll = (productIsSelected: boolean, clientInfoIsGiven: boolean): void => {
    const scrollY = window.scrollY;
    if (typeof scrollY === 'undefined' || scrollY === null) return;

    let scrollPositionTrigger = 62;

    if (!productIsSelected || !clientInfoIsGiven) {
      scrollPositionTrigger = 27;
    }

    collapseMobileHeader(scrollY > scrollPositionTrigger);
  };

  const onMainContentScroll = useCallback(
    () => handleScroll(product != null, !!(client && client.gender != null)),
    [client, product]
  );

  const title = props.title && !isLoading ? props.title : text('quoteApplication.submission.processing');

  const header = (
    <TransactionFlowHeader
      progress={props.progress}
      legalLinks={getLegalItems()}
      title={showMobileTitle ? props.title : ''}
      collapseMobileHeader={isMobileHeaderCollapsed}
      showScheduleCallAction={showScheduleCallAction}
      isMobile={props.isMobile}
    />
  );

  return (
    <TransactionFlowBaseLayout
      id='quote-application'
      popup={props.popup}
      leftPanel={!props.isMobile && <TransactionFlowSidebar title={title} />}
      header={header}
      hideHeaderOnDesktop={hideHeaderOnDesktop}
      mainContent={<Container px={{ xs: props.isFullWidthLayout ? 0 : 4, md: 0 }}>{children}</Container>}
      onMainContentScroll={onMainContentScroll}
    />
  );
}

export const TransactionFlowLayout = withResponsive(TransactionFlowLayoutWithResponsive);
