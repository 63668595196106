import _ from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { PlanFinderBaseLayout, ResponsiveProps, withResponsive } from '@breathelife/ui-components';

import { TitleHelmet } from '../../Components/TitleHelmet/TitleHelmetContainer';
import { CarrierContext } from '../../Context/CarrierContext';
import { PlanFinderContent } from './Content';
import { PlanFinderFooter } from './Footer';
import { ProgressHeader as PlanFinderHeader } from './Header';

type Props = {
  title: string;
  subtitle?: string;
  questionId: string;
  progress: number;
  popup: React.ReactElement | null;
  isLoading: boolean;
  children: React.ReactNode;
  useEngine?: boolean;
} & ResponsiveProps;

const LeftImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
`;

const MobileImageContainer = styled.div`
  min-height: 62vw;
  @media (min-width: 572px) {
    min-height: 45vw;
  }
`;

const FixedContainer = styled.div`
  position: fixed;
  min-height: inherit;
  width: 100%;
`;

const MobileImage = styled.img`
  display: block;
  width: 100%;
  max-width: 420px;
  height: auto;
  margin: 0 auto;
`;

type ParallaxProps = {
  parallax?: boolean;
};

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fonts.size.title.mobile};
  font-weight: ${(props) => props.theme.fonts.weight.title};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    font-size: ${(props) => props.theme.fonts.size.title.desktop};
  }
`;

const Text = styled.p`
  font-size: ${(props) => props.theme.fonts.size.description.desktop};
  color: ${(props) => props.theme.colors.grey.darker};
`;

const BaseLayoutStyleWrapper = styled.div<ParallaxProps>`
  &&&&& {
    [role='main'] {
      background-color: ${(props) => props.theme.colors.white};
      @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
        background-color: unset;
      }
      > div:first-child {
        margin: 3vh auto;

        @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
          margin: 7vh auto;
        }

        @media (min-width: ${(props) => props.theme.breakpoints.desktop.min}) {
          max-width: 1168px;
        }

        @media (max-width: ${(props) => props.theme.breakpoints.tablet.max}) and (min-width: ${(props) =>
            props.theme.breakpoints.tablet.min}) {
          max-width: 944px;
          padding: 0;
        }

        @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
          padding: 0;
        }
      }

      ${(props) =>
        props.parallax &&
        `
          z-index: 1;
          padding: 0px 20px 20px 20px;
          box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.25);
        `}
    }
    section:first-child {
      /* Disable sticky on the section so the image doesn't move as you scroll
       (it went below the right section box if the window was really small) */
      top: unset;
    }
    section:nth-child(2) {
      /* Allow the right section to expand bigger over the absolute image in the left section */
      max-width: unset;
    }
  }
`;

function PlanFinderLayoutWithResponsive(props: Props): React.ReactElement {
  const { images } = useContext(CarrierContext);

  const imageSrc = _.get(images, props.questionId);

  const fixedImage = props.isMobile && (
    <MobileImageContainer>
      <FixedContainer>
        <MobileImage src={imageSrc}></MobileImage>
      </FixedContainer>
    </MobileImageContainer>
  );

  const header = <PlanFinderHeader progress={props.progress} mobileContent={fixedImage} parallax={props.isMobile} />;

  const content = (
    <PlanFinderContent isLoading={props.isLoading}>
      {props.useEngine && (
        <React.Fragment>
          <Title>{props.title}</Title>
          {props.subtitle && <Text>{props.subtitle}</Text>}
        </React.Fragment>
      )}
      {props.children}
    </PlanFinderContent>
  );

  return (
    <BaseLayoutStyleWrapper parallax={props.isMobile}>
      <TitleHelmet text={props.title} />
      <PlanFinderBaseLayout
        header={header}
        popup={props.popup}
        leftSectionContent={!props.isMobile && <LeftImage src={imageSrc} alt='' />}
        rightSectionContent={content}
        footer={<PlanFinderFooter />}
      />
    </BaseLayoutStyleWrapper>
  );
}

export const PlanFinderLayout = withResponsive(PlanFinderLayoutWithResponsive);
