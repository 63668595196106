export default {
  home: (basePath: string): string => basePath,
  questions: (basePath: string): string => `${basePath}/questions`,
  genericLinkLanding: (basePath: string): string => `${basePath}/start`,
  submit: (basePath: string): string => `${basePath}/submit-application`,
  contactConfirmation: (basePath: string): string => `${basePath}/contact-confirmation`,
  fourOhFour: (basePath: string): string => `${basePath}/404`,
  error: (basePath: string): string => `${basePath}/error`,
  new: (basePath: string): string => `${basePath}/new`,
  expired: (basePath: string): string => `${basePath}/expired`,
  external: (tokenPath = ':authToken'): string => `/external/${tokenPath}`,
};
