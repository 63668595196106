import _ from 'lodash';

import config from '@breathelife/config/frontend';
import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/react-tracking';

import { getLanguageRegion } from '../../Localization/Localizer';

export async function identifyApplicant(applicationId: string): Promise<boolean> {
  // Must use English company name
  const companyName = config.get<string>('carrier.companyName.en');
  if (!applicationId || !companyName) {
    console.warn('Tracking properties missing');
    return false;
  }

  const groupId = `${_.snakeCase(companyName)}_user_group`;

  // Wait for this to finish before making `group` call
  await new Promise<void>((resolve) => {
    TypewriterTracking.identify(
      {
        userId: hash(applicationId),
        traits: {
          userType: 'consumer',
          locale: getLanguageRegion(),
        },
      },
      resolve
    );
  });

  await new Promise<void>((resolve) => {
    TypewriterTracking.group(groupId, undefined, resolve);
  });

  return true;
}
