import Box from '@material-ui/core/Box';
import React from 'react';

import { List, ListItem, Text } from '@breathelife/ui-components';

import { Option } from '../../../Models/Product';
import { Content, OptionsContainer } from './Styles';

type OptionsProps = {
  options: Option[];
};

export function Options(props: OptionsProps): React.ReactElement | null {
  const { options } = props;
  if (!options.length) return null;

  return (
    <OptionsContainer py={3}>
      <Content px={3}>
        <List noSpacing>
          {options.map((option) => (
            <ListItem key={option.title} iconType={'checkmark'}>
              <Box mb={1}>
                <Text noSpacing variant='legal' grey={90}>
                  {option.title}
                </Text>
              </Box>
            </ListItem>
          ))}
        </List>
      </Content>
    </OptionsContainer>
  );
}
