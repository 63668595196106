import { AddonCheckboxState, AddonsOptionUpsell } from '@breathelife/types';

export function getTotalPremium(
  addons: AddonsOptionUpsell[],
  checkedAddons: AddonCheckboxState,
  referencePremium: number | undefined
): number {
  const totalPremium = addons.reduce((total, addon) => {
    return checkedAddons[addon.id] === true ? total + addon.price : total;
  }, referencePremium ?? 0);

  return totalPremium;
}

export function updateSelectAddons(checkedAddons: AddonCheckboxState): string[] {
  return Object.keys(checkedAddons).filter((addon) => checkedAddons[addon]);
}

export function buildAddonCheckboxInitialStates(
  addons: AddonsOptionUpsell[],
  selectedAddons: string[]
): AddonCheckboxState {
  const addonCheckboxStates: AddonCheckboxState = {};
  addons.forEach((addon) => {
    addonCheckboxStates[addon.id] = selectedAddons.includes(addon.id);
    if (addon.disabled) {
      addonCheckboxStates[addon.id] = true; // Set to true if default addon
    }
  });
  return addonCheckboxStates;
}
