import * as Sentry from '@sentry/browser';
import { push as routerPush } from 'connected-react-router';
import { ConsumerFlowStore } from 'index';
import { Dispatch } from 'redux';

import Urls from '../../Navigation/Urls';
import ApiService from '../../Services/ApiService';
import { settingsSlice } from '../Settings/SettingsSlice';

export const fetchSettings =
  () =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    try {
      const response = await ApiService.fetchPublicSettings();
      dispatch(settingsSlice.actions.setSettings({ settings: response.data }));
    } catch (error) {
      dispatch(settingsSlice.actions.setHasErrorPayload({ hasLoadingError: true }));
      const message = `Unable to load settings in the consumer flow. Error: ${JSON.stringify(error)}`;
      Sentry.captureMessage(message, Sentry.Severity.Error);

      const { basePath } = store().consumerFlow.navigation;
      dispatch(routerPush(Urls.error(basePath)));
    }
  };
