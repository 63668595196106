import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/react-tracking';
import { MessageType, Notification as BaseNotification } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';

export function Notification(): React.ReactElement | null {
  const dispatch = useDispatch();
  const applicationId =
    useCxSelector((state) => state.consumerFlow.insuranceApplication.insuranceApplication?.id) ?? null;
  const notification = useCxSelector((store) => store.consumerFlow.notification);

  useEffect(() => {
    if (notification.type === MessageType.error) {
      // TODO: set a notification id in the Redux store and pass it to errorId instead of the message
      TypewriterTracking.errorOccurred({
        error: notification.message ?? '',
        hashedId: hash(applicationId),
      });
    }
  }, [applicationId, notification]);
  const resetError = useCallback(() => dispatch(notificationSlice.actions.setNotification({})), [dispatch]);

  if (!notification.type) return null;

  return (
    <BaseNotification
      display={!!notification.message}
      title={notification.title}
      message={notification.message}
      type={notification.type}
      autoHideDuration={notification.autoHideDuration}
      onClose={resetError}
      position={{
        vertical: 'top',
        horizontal: 'right',
      }}
    />
  );
}
