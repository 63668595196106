import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { authenticationSlice } from '@breathelife/redux';
import { Loader } from '@breathelife/ui-components';

import Urls from '../../Navigation/Urls';
import { insuranceApplicationSlice } from '../../Redux/InsuranceApplication/InsuranceApplicationSlice';
import { resetApplication } from '../../Redux/resetOperations';
import * as ApplicationService from '../../Services/ApplicationService';
import { seedApplication } from './seedApplication';

export type Props = {
  seedData?: unknown;
  redirectPath?: string;
  appIdKey?: string;
  hasExternalApplicationAuth?: boolean;
  basePath?: string;
  getHeaders?: (body: unknown) => Record<string, string | number | boolean>;
};

export function CreateMockApplicationView(props: Props): React.ReactElement | null {
  const { redirectPath, appIdKey, hasExternalApplicationAuth, basePath, getHeaders } = props;
  const applicationData = props.seedData ?? seedApplication;

  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [appId, setAppId] = useState<string>('');

  const [errorRedirect, setErrorRedirect] = useState(false);

  useEffect(() => {
    (async (): Promise<void> => {
      const payload = applicationData as Record<string, unknown>;

      const data = { ...payload, isTest: true };

      // the external flows don't include an application JWT with a nonce
      // which will cause the shared API gateway to throw an error
      const response = hasExternalApplicationAuth
        ? await axios.post('/v1/applications', data, { headers: getHeaders ? getHeaders(data) : {} })
        : await ApplicationService.createApplication(data);

      if (!hasExternalApplicationAuth) {
        const { token, application } = response as ApplicationService.QuestionResponse;
        dispatch(insuranceApplicationSlice.actions.reset());
        dispatch(authenticationSlice.actions.setToken({ token }));
        setAppId(application?.id);
        return;
      }

      try {
        const token = (response as AxiosResponse).data.token;

        dispatch(resetApplication());
        setToken(token);
      } catch (error) {
        setErrorRedirect(true);
      }
    })();
  }, [dispatch, applicationData, hasExternalApplicationAuth]);

  if (errorRedirect) return <Redirect to={Urls.fourOhFour(redirectPath ?? '/plan')} />;

  if (token) {
    const redirectTo = Urls.external(token);
    return <Redirect to={redirectTo} />;
  } else if (appId) {
    let redirectTo = `${Urls.home(basePath ?? '/plan')}?id=${appId}`;

    if (redirectPath && appIdKey) {
      redirectTo = `${redirectPath}?${appIdKey}=${appId}`;
    }

    return <Redirect to={redirectTo} />;
  }

  return <Loader />;
}
