import { push as routerPush } from 'connected-react-router';
import { Dispatch } from 'redux';

import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/react-tracking';
import { errorSlice, progressSlice } from '@breathelife/redux';

import { text } from '../../Localization/Localizer';
import { Step } from '../../Models/Step';
import Urls from '../../Navigation/Urls';
import ApiService from '../../Services/ApiService';
import * as ApplicationService from '../../Services/ApplicationService';
import { getLocalizedErrorMessage } from '../../Services/Errors/ServiceErrorCodes';
import { insuranceApplicationSlice } from '../InsuranceApplication/InsuranceApplicationSlice';
import { hideLoadingPage, navigateToQuestion as navigateToStep } from '../Navigation/NavigationOperations';
import { notificationSlice } from '../Notification/NotificationSlice';
import { ConsumerFlowStore } from '../Store';
import { stepSlice } from './StepSlice';

const stepActions = stepSlice.actions;
const progressActions = progressSlice.actions;
const errorActions = errorSlice.actions;
const notificationActions = notificationSlice.actions;
const insuranceApplicationActions = insuranceApplicationSlice.actions;

export const answerQuestionAndNavigate =
  (stepId: string, answer: any) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    dispatch(stepActions.setIsLoading({ isLoading: true, stepId }));

    const insuranceApplication = store().consumerFlow.insuranceApplication.insuranceApplication;
    const currentStepId = store().consumerFlow.step.currentStep?.id;

    if (!insuranceApplication) {
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return;
    }

    let nextStep = null;

    try {
      const response = await ApplicationService.sendQuestionAnswer(insuranceApplication.id, stepId, answer);
      dispatch(errorActions.setError({ errorId: null }));

      dispatch(insuranceApplicationActions.setInsuranceApplication({ insuranceApplication: response.application }));

      if (response.step) {
        dispatch(progressActions.setProgress({ progress: response.progressTotal }));
        dispatch(stepActions.setHasErrors({ hasErrors: response.hasErrors ?? false }));
        nextStep = response.step;
        dispatch(stepActions.setNextStep({ step: nextStep }));
      }
    } catch (e) {
      const errorResponse = e.response?.data;
      const errorId = errorResponse?.message;
      const serviceErrorCode = errorResponse?.data?.serviceErrorCode;

      dispatch(errorActions.setError({ errorId }));
      dispatch(notificationActions.setError({ message: getLocalizedErrorMessage(serviceErrorCode) }));

      TypewriterTracking.stepErrored({
        error: errorId,
        hashedId: hash(insuranceApplication.id),
        stepId: currentStepId ?? '',
      });
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));

    if (nextStep) {
      await navigateToStep(nextStep.id)(dispatch, store);
    }
  };

export const setCurrentQuestionById =
  (stepId: string) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    const nextStep = store().consumerFlow.step.nextStep;
    const insuranceApplication = store().consumerFlow.insuranceApplication.insuranceApplication;

    if (!insuranceApplication) {
      dispatch(routerPush(Urls.home(store().consumerFlow.navigation.basePath)));
      dispatch(notificationActions.setError({ message: text('apiErrors.fetchStepNoApplication') }));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return;
    }

    if (nextStep?.id === stepId) {
      // If the question's data was cached in the store by a previous operation, use this
      // data rather than making a roundtrip to the backend to get the question.
      dispatch(stepActions.setCurrentStep({ step: nextStep }));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return;
    }

    try {
      dispatch(stepActions.setIsLoading({ isLoading: true, stepId }));
      const response = await ApplicationService.getQuestion(insuranceApplication.id, stepId);
      // If this operation has been called with a different questionById while the network request was in flight,
      // skip this operation here and do not update the store.
      const loadingStepId = store().consumerFlow.step.loadingStepId;
      if (loadingStepId === stepId) {
        dispatch(stepActions.setCurrentStep({ step: response.step }));
        dispatch(insuranceApplicationActions.setInsuranceApplication({ insuranceApplication: response.application }));
        dispatch(progressActions.setProgress({ progress: response.progressTotal }));
        dispatch(stepActions.setHasErrors({ hasErrors: response.hasErrors ?? false }));
      }
    } catch (e: any) {
      dispatch(notificationActions.setError({ message: e.response?.message }));
      if (e.response?.status === 404) {
        dispatch(routerPush(Urls.fourOhFour(store().consumerFlow.navigation.basePath)));
      }
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
  };

export const getPreviousQuestion =
  (stepId: string) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<Step | null> => {
    dispatch(stepActions.setIsLoading({ isLoading: true, stepId }));

    const insuranceApplication = store().consumerFlow.insuranceApplication.insuranceApplication;
    if (!insuranceApplication || !stepId) {
      dispatch(notificationActions.setError({ message: text('apiErrors.retrieving') }));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return null;
    }

    let previousQuestion = null;

    try {
      const response = await ApplicationService.getPreviousQuestionData(insuranceApplication.id, stepId);

      // If a new question started fetching while the request was in-flight, cancel this operation.
      if (!response.step || store().consumerFlow.step.loadingStepId !== stepId) return null;

      previousQuestion = response.step;
    } catch (e) {
      dispatch(notificationActions.setError({ message: e.response?.message }));
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));

    return previousQuestion;
  };

export const navigateToPreviousQuestion =
  (stepId: string) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    if (store().consumerFlow.navigation.loadingPage.isVisible) {
      // if the loading page is present, just hide it, but stay on the same URL
      await hideLoadingPage()(dispatch);
      return;
    }

    dispatch(stepActions.setIsLoading({ isLoading: true, stepId: stepId }));

    const insuranceApplication = store().consumerFlow.insuranceApplication.insuranceApplication;
    if (!insuranceApplication) {
      dispatch(notificationActions.setError({ message: text('apiErrors.retrieving') }));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return;
    }

    const isLandingStep = store().consumerFlow.configuration.landingStepsIds.includes(stepId);

    // If we are on the first question, navigate back to the homepage.
    if (isLandingStep) {
      dispatch(routerPush(Urls.home(store().consumerFlow.navigation.basePath)));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return;
    }

    let previousStepId;

    const response = await ApplicationService.getPreviousQuestionData(insuranceApplication.id, stepId);

    // If a new question started fetching while the request was in-flight, cancel this operation.
    if (store().consumerFlow.step.loadingStepId !== stepId) return;
    dispatch(insuranceApplicationActions.setInsuranceApplication({ insuranceApplication: response.application }));

    if (response.step) {
      dispatch(stepActions.setNextStep({ step: response.step }));
      previousStepId = response.step.id;
    }

    dispatch(errorActions.setError({ errorId: null }));

    if (previousStepId) {
      await navigateToStep(previousStepId as string)(dispatch, store);
    } else {
      // The landing / home page is not defined like the other questions but we need a name for it
      previousStepId = 'landingPage';
      dispatch(routerPush(previousStepId));
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
  };

// TODO: This is almost a duplicate of getPreviousQuestion, we might be able to merge it
export const getStep =
  (stepId: string) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<Step | null> => {
    dispatch(stepActions.setIsLoading({ isLoading: true, stepId }));

    const insuranceApplication = store().consumerFlow.insuranceApplication.insuranceApplication;
    if (!insuranceApplication || !stepId) {
      dispatch(notificationActions.setError({ message: text('apiErrors.retrieving') }));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return null;
    }

    let nextStep = null;

    try {
      const response = await ApplicationService.getQuestion(insuranceApplication.id, stepId);

      // If a new question started fetching while the request was in-flight, cancel this operation.
      if (!response.step || store().consumerFlow.step.loadingStepId !== stepId) return null;
      dispatch(insuranceApplicationActions.setInsuranceApplication({ insuranceApplication: response.application }));

      nextStep = response.step;
      dispatch(progressActions.setProgress({ progress: response.progressTotal }));
      dispatch(stepActions.setNextStep({ step: nextStep }));
    } catch (e) {
      dispatch(notificationActions.setError({ message: text('apiErrors.fetchFirstQuestion') }));
      if (e.response.status === 404) {
        dispatch(routerPush(Urls.fourOhFour(store().consumerFlow.navigation.basePath)));
      }
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));

    return nextStep;
  };

export const getLandingStep =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(stepActions.setIsLoading({ isLoading: true, stepId: null }));

    try {
      const { data: response } = await ApiService.consumer.getQuestionnaireLandingStep();
      dispatch(stepSlice.actions.setNodeIdToAnswerPathMap({ nodeIdToAnswerPathMap: response.nodeIdToAnswerPathMap }));
      dispatch(stepSlice.actions.setCurrentStep({ step: response.step }));
    } catch (error) {
      dispatch(notificationSlice.actions.setError({ message: text('apiErrors.fetchLandingStep') }));
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
  };
