import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { hash } from '@breathelife/hash';
import { ScreenName, TypewriterTracking } from '@breathelife/react-tracking';
import { Language } from '@breathelife/types';
import { LocalizationStrings, OnSubmitScheduleDataType } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { assigneeIsInterlocutor } from '../../Helpers/interlocutorHelper';
import { getSchedulingTimeFrames } from '../../Helpers/schedulingTimeFrames';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { text } from '../../Localization/Localizer';
import * as communicationOperations from '../../Redux/Communication/CommunicationOperations';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { ScheduleCallTabs } from './ScheduleCallTabs';

type Props = {
  language: Language;
  localizationStrings?: Partial<LocalizationStrings>;
};

export function ScheduleCallTabsContainer(props: Props): React.ReactElement {
  const dispatch = useDispatch();
  const { interlocutorIds } = useContext(CarrierContext);

  const applicationState = useCxSelector((store) => store.consumerFlow.insuranceApplication);
  const insuranceApplication = applicationState.insuranceApplication;
  const applicationId = insuranceApplication?.id;

  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);

  const communication = useCxSelector((store) => store.consumerFlow.communication);

  const [isCommunicationPreferencesSubmitted, setIsCommunicationPreferencesSubmitted] = useState(false);

  const schedulingTimeFrameConfig = useCxSelector((store) => store.consumerFlow.configuration.schedulingTimeFrames);
  const schedulingTimeFrames = getSchedulingTimeFrames(schedulingTimeFrameConfig);

  const isInterlocutor = assigneeIsInterlocutor(interlocutorIds, applicationAssignee);

  useEffect(() => {
    if (isCommunicationPreferencesSubmitted) return;

    TypewriterTracking.viewedScreen({
      screenName: ScreenName.scheduleACallForm,
      hashedId: hash(applicationId as string),
    });
  }, [isCommunicationPreferencesSubmitted, applicationId]);

  const onSubmitSchedule = useCallback(
    async (scheduleCallPreferences: OnSubmitScheduleDataType) => {
      try {
        await dispatch(
          communicationOperations.setCommunicationPreferences(insuranceApplication?.id || '', scheduleCallPreferences)
        );
        setIsCommunicationPreferencesSubmitted(true);
      } catch (err) {
        dispatch(notificationSlice.actions.setError({ message: text('scheduleCall.error') }));
      }
    },
    [dispatch, insuranceApplication]
  );

  const form = {
    ...communication.userInfo,
    bestMoments: communication.preferences?.bestMoments,
    preferredMethods: communication.preferences?.preferredMethods,
    isSubmitting: communication.isLoading,
    schedulingTimeFrames: schedulingTimeFrames,
    onSubmitSchedule: onSubmitSchedule,
    isSubmitted: isCommunicationPreferencesSubmitted,
  };

  return (
    <ScheduleCallTabs
      form={form}
      application={insuranceApplication}
      applicationAssigneeImage={applicationAssignee?.picture}
      hasContactPersonInfo={!!applicationAssignee}
      applicationAssigneePhone={applicationAssignee?.phone}
      applicationAssigneeEmail={applicationAssignee?.email}
      onSubmitContactForm={(onSubmitContactArgs: OnSubmitScheduleDataType) => {
        form.onSubmitSchedule(onSubmitContactArgs);
      }}
      language={props.language}
      localizationStrings={props.localizationStrings}
      isPlural={isInterlocutor}
    />
  );
}
