import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { AddonMetadata, LocalizedInsuranceProduct, QuotePerProduct } from '@breathelife/types';

import { ApplicationState } from 'Models/ApplicationState';
import { LegacyProductInfo } from 'Models/Product';

export type InsuranceApplicationState = {
  insuranceApplication?: ApplicationState;
  recommendedCoverage?: number;
  products: LegacyProductInfo[] | LocalizedInsuranceProduct[];
  quotes?: QuotePerProduct;
  isLoading: boolean;
  addonsMetadata?: AddonMetadata;
  referencePremium?: number;
};

const defaultState = {
  isLoading: false,
  products: [],
};

type InsuranceApplicationPayload = {
  insuranceApplication: ApplicationState;
};

type RecommendedCoveragePayload = number;
type QuotesPayload = QuotePerProduct;
type ProductsPayload = LegacyProductInfo[] | LocalizedInsuranceProduct[];
type AddonsMetadataPayload = AddonMetadata;
type ReferencePremiumPayload = number;

type ActionType = {
  setInsuranceApplication: (
    state: InsuranceApplicationState,
    { payload }: PayloadAction<InsuranceApplicationPayload>
  ) => void;
  setRecommendedCoverage: (
    state: InsuranceApplicationState,
    { payload }: PayloadAction<RecommendedCoveragePayload>
  ) => void;
  setQuotes: (state: InsuranceApplicationState, { payload }: PayloadAction<QuotesPayload>) => void;
  setProducts: (state: InsuranceApplicationState, { payload }: PayloadAction<ProductsPayload>) => void;
  setIsLoading: (state: InsuranceApplicationState, { payload }: PayloadAction<boolean>) => void;
  reset: (state: InsuranceApplicationState) => void;
  resetQuotes: (state: InsuranceApplicationState) => void;
  setAddons: (state: InsuranceApplicationState, { payload }: PayloadAction<AddonsMetadataPayload>) => void;
  setReferencePremium: (state: InsuranceApplicationState, { payload }: PayloadAction<ReferencePremiumPayload>) => void;
};

export const insuranceApplicationSlice = createSlice<InsuranceApplicationState, ActionType>({
  name: 'consumerFlow/insuranceApplication',
  initialState: defaultState,
  reducers: {
    setInsuranceApplication(state: InsuranceApplicationState, { payload }: PayloadAction<InsuranceApplicationPayload>) {
      state.insuranceApplication = payload.insuranceApplication;
    },
    setRecommendedCoverage(state: InsuranceApplicationState, { payload }: PayloadAction<RecommendedCoveragePayload>) {
      state.recommendedCoverage = payload;
    },
    setProducts(state: InsuranceApplicationState, { payload }: PayloadAction<ProductsPayload>) {
      if (!_.isEqual(state.products, payload)) state.products = payload;
    },
    setIsLoading(state: InsuranceApplicationState, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setQuotes(state: InsuranceApplicationState, { payload }: PayloadAction<QuotesPayload>) {
      state.quotes = payload;
    },
    reset(state: InsuranceApplicationState) {
      state.insuranceApplication = undefined;
      state.recommendedCoverage = undefined;
      state.products = [];
      state.isLoading = false;
      state.quotes = undefined;
    },
    resetQuotes(state: InsuranceApplicationState) {
      state.recommendedCoverage = undefined;
      state.isLoading = false;
      state.quotes = undefined;
    },
    setAddons(state: InsuranceApplicationState, { payload }: PayloadAction<AddonsMetadataPayload>) {
      state.addonsMetadata = payload;
    },
    setReferencePremium(state: InsuranceApplicationState, { payload }: PayloadAction<ReferencePremiumPayload>) {
      state.referencePremium = payload;
    },
  },
});
