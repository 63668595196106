import React from 'react';

import { CenteredLayout as BaseCenteredLayout, ResponsiveProps } from '@breathelife/ui-components';

import { TitleHelmet } from '../../Components/TitleHelmet/TitleHelmetContainer';
import { text } from '../../Localization/Localizer';
import { ApplicationAssignee } from '../../Models/ApplicationAssignee';
import { Footer } from './Footer';
import { BaseHeader, HeaderPlaceHolder } from './Header';
import { LoadingContent, LoadingContentProps } from './LoadingContent';

type Props = {
  title: string;
  subtitle?: string | React.ReactNode;
  offsetContent?: React.ReactElement;
  questionId?: string;
  progress?: number;
  isLoading: boolean;
  loadingContent?: LoadingContentProps;
  children: React.ReactNode;
  assignee?: ApplicationAssignee;
  headerVariant?: 'default' | 'placeholder' | 'none';
  hideScheduleCall?: boolean;
  hideProgress?: boolean;
  isFullWidthLayout?: boolean;
  useEngine?: boolean;
} & ResponsiveProps;

export function CenteredLayout(props: Props): React.ReactElement {
  const { headerVariant = 'default', isLoading } = props;

  return (
    <>
      <TitleHelmet text={props.isLoading ? text('loading') : props.title} />
      <BaseCenteredLayout
        header={
          <React.Fragment>
            {headerVariant === 'default' && (
              <BaseHeader hideScheduleCall={props.hideScheduleCall} hideProgress={props.hideProgress} />
            )}
            {headerVariant === 'placeholder' && <HeaderPlaceHolder />}
          </React.Fragment>
        }
        title={isLoading ? '' : props.title}
        subtitle={isLoading ? '' : props.subtitle}
        offsetContent={isLoading ? undefined : props.offsetContent}
        isFullWidthLayout={props.isFullWidthLayout}
        footer={<Footer />}
      >
        {isLoading && props.loadingContent ? <LoadingContent {...props.loadingContent} /> : props.children}
      </BaseCenteredLayout>
    </>
  );
}
