import { Dispatch } from 'redux';

import * as DebugToolbarService from '../../Services/DebugToolbarService';
import { insuranceApplicationSlice } from '../InsuranceApplication/InsuranceApplicationSlice';
import { navigateToQuestion } from '../Navigation/NavigationOperations';
import { notificationSlice } from '../Notification/NotificationSlice';

const { actions } = insuranceApplicationSlice;

export function updateAnswersUntil(applicationId: string, subsectionId: string) {
  return async function (dispatch: Dispatch): Promise<void> {
    dispatch(insuranceApplicationSlice.actions.setIsLoading(true));

    try {
      const response = await DebugToolbarService.updateApplicationWithDebugToolbar(applicationId, {
        subsectionId,
      });
      dispatch(actions.setInsuranceApplication({ insuranceApplication: response.application }));
      dispatch(
        notificationSlice.actions.setSuccess({
          message: `Application filled up to '${subsectionId}' step.`,
          autoHideDuration: 8000,
        })
      );

      // TODO: find a way to handle this without the typing issue
      //@ts-ignore
      dispatch(navigateToQuestion(subsectionId));
    } catch (err) {
      dispatch(
        notificationSlice.actions.setError({
          message: err.response?.message,
        })
      );
    } finally {
      dispatch(actions.setIsLoading(false));
    }
  };
}
