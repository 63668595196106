import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import {
  AdvisorInfoPanel as AdvisorInfoPanelBase,
  Button as SharedButton,
  RunningText,
  Title as TitleBase,
} from '@breathelife/ui-components';

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    align-items: flex-start;
    max-width: 520px;
    margin-top: 20%;
  }
`;

export const Title = styled(TitleBase)`
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    text-align: left;
  }
`;

export const StartButton = styled(SharedButton)`
  &&&& {
    border: none;
    margin-bottom: 32px;
    min-width: 100%;

    span {
      transform: unset;
      text-transform: none;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.button.base.background.hover};
    }
    &:focus {
      &:not(:active) {
        outline: 3px solid ${(props) => props.theme.colors.button.base.border.focus};
      }
    }
    &:active {
      background-color: ${(props) => props.theme.colors.button.base.background.active};
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    min-width: 200px !important;
  }
`;

export const AdvisorInfoPanel = styled(AdvisorInfoPanelBase)`
  *:first-child {
    margin-right: 0 !important;
  }
`;

export const LanguageButton = styled.button`
  border: none;
  background-color: unset;
  text-decoration: none;
  font-weight: ${(props) => props.theme.fonts.weight.heavy};
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary.default};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  &&&&& {
    button {
      margin-bottom: 56px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    flex-direction: row;
    &&&&& {
      button {
        margin-bottom: 0;
        margin-right: 40px;
      }
    }
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 70px;
  right: 30px;
`;

export const StyledRunningText = styled(RunningText)`
  color: ${(props) => props.theme.colors.grey[90]};
`;
