import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { hash } from '@breathelife/hash';
import { ButtonName, TypewriterTracking } from '@breathelife/react-tracking';
import { CenteredLayoutHeader, HeaderMenu, MenuItemTypes } from '@breathelife/ui-components';

import { AdvisorInfoPanelContainer } from '../../Components/AdvisorInfoPanel/AdvisorInfoPanelContainer';
import { CarrierLogo } from '../../Components/CarrierLogo/CarrierLogo';
import { ScheduleCallButtonContainer } from '../../Components/ScheduleCallButton/ScheduleCallButtonContainer';
import { CarrierContext } from '../../Context/CarrierContext';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { getOtherLocales, shortLocale, text } from '../../Localization/Localizer';
import { getSelectedProductInformation } from '../../Redux/InsuranceApplication/InsuranceApplicationSelectors';
import { switchLanguage } from '../../Redux/SwitchLanguage/SwitchLanguageOperations';
import { ProductSummary } from './ProductSummary/ProductSummary';

function HeaderButtons(props: { hideScheduleCall?: boolean }): React.ReactElement {
  const dispatch = useDispatch();
  const otherLanguages = getOtherLocales();
  const applicationId =
    useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication?.id) ?? null;

  const languageSwitcherButtons = otherLanguages.map((language) => ({
    text: text(`languages.${language}`),
    itemType: MenuItemTypes.button,
    onClick: async () => {
      TypewriterTracking.clickedButton({
        buttonName: ButtonName.switchedLanguage,
        hashedId: hash(applicationId),
      });
      await dispatch(switchLanguage(applicationId, language));
    },
  }));

  return (
    <React.Fragment>
      {!props.hideScheduleCall && (
        <Box ml='auto'>
          <ScheduleCallButtonContainer />
        </Box>
      )}
      {!_.isEmpty(languageSwitcherButtons) && (
        <Box ml={props.hideScheduleCall ? 'auto' : 2}>
          <HeaderMenu items={[...languageSwitcherButtons]} />
        </Box>
      )}
    </React.Fragment>
  );
}

type Props = {
  hideScheduleCall?: boolean;
  hideProgress?: boolean;
};

export function BaseHeader(props: Props): React.ReactElement {
  const progress = useCxSelector((store) => store.consumerFlow.progress.progress);
  const progressText = text('header.completion', { progress });
  const { images, enableHeaderProductSummary } = useContext(CarrierContext);
  const carrierLogo = _.get(images, `product.logo.${shortLocale()}`);

  const productInformation = useCxSelector(getSelectedProductInformation);
  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);
  const leftContent = applicationAssignee ? <AdvisorInfoPanelContainer /> : <CarrierLogo />;

  return (
    <CenteredLayoutHeader
      leftContent={leftContent}
      rightContent={<HeaderButtons hideScheduleCall={props.hideScheduleCall} />}
      progress={props.hideProgress ? 0 : progress}
      progressText={props.hideProgress ? undefined : progressText}
      productSummary={
        enableHeaderProductSummary &&
        productInformation && <ProductSummary productInformation={productInformation} carrierLogo={carrierLogo} />
      }
    />
  );
}

export function HeaderPlaceHolder(): React.ReactElement {
  return <Box py={{ xs: 3 }} />;
}
