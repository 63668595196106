import _ from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { IconName } from '@breathelife/types';
import { Icon } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { useCxSelector } from '../../Hooks/useCxSelector';

const Title = styled.h1`
  margin: 30px 0;
  white-space: pre-wrap;
  color: ${(props) => props.theme.colors.base.primary};
  font-size: ${(props) => props.theme.fonts.size.title.mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    font-size: ${(props) => props.theme.fonts.size.title.desktop};
  }
`;

const Subtitle = styled.h2`
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fonts.size.title.mobile};
  font-family: ${(props) => props.theme.fonts.family.fallbacks};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    font-size: ${(props) => props.theme.fonts.size.title.tablet};
  }
`;

const TextContainer = styled.div`
  margin-bottom: 20px;
`;

const LoaderContainer = styled.div`
  margin-top: 70px;
  text-align: center;
`;

const LoaderImage = styled.img`
  height: 140px;
  width: auto;
`;

export function LoadingContent(): React.ReactElement {
  const { images } = useContext(CarrierContext);

  const loadingMessages = useCxSelector((store) => store.consumerFlow.navigation.loadingPage.messages);
  return (
    <div>
      <TextContainer>
        <Title>{loadingMessages?.title}</Title>
        <Subtitle>{loadingMessages?.subtitle}</Subtitle>
        {loadingMessages?.descriptions?.map((message: string) => (
          <p>{message}</p>
        ))}
      </TextContainer>
      <LoaderContainer>
        {_.get(images, 'planFinder.loading') ? (
          <LoaderImage src={_.get(images, 'planFinder.loading')} alt='' />
        ) : (
          <Icon name={IconName.loading} size='140px' />
        )}
      </LoaderContainer>
    </div>
  );
}
