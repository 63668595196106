import { captureException } from '@sentry/browser';
import { useStripe } from '@stripe/react-stripe-js';
import { SetupIntent } from '@stripe/stripe-js';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PaymentServiceProvider } from '@breathelife/types';

import { CarrierContext } from '../../Context/CarrierContext';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { text } from '../../Localization/Localizer';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { createSetupIntent, fetchClientSecret } from '../../Redux/Payment/PaymentOperations';
import { PaymentView } from './PaymentView';

type PaymentViewContainerProps = {
  onNextClick: () => void;
  onPreviousClick: () => void;
};

export function PaymentViewContainer(props: PaymentViewContainerProps): React.ReactElement {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { features } = useContext(CarrierContext);
  const insuranceApplication = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication);
  const { clientSecret } = useCxSelector((store) => store.consumerFlow.payment);
  const { onNextClick, onPreviousClick } = props;

  const isStripeEnabled =
    features.payments.enabled && features.payments.serviceProvider === PaymentServiceProvider.STRIPE;

  if (!isStripeEnabled) {
    const err = new Error('Stripe is not enabled.');
    captureException(err);
    throw err;
  }

  // Use this state to prevent infinite re-submission of the current step due to updated reference to the `onNextClick` function
  // This would only happen when the user navigates to the current step when the application has been submitted
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [setupIntent, setSetupIntent] = useState<SetupIntent>();
  const [pollingTimeoutId, setPollingTimeoutId] = useState<NodeJS.Timeout>();

  const retrieveSetupIntent = useCallback(async () => {
    if (!stripe || !insuranceApplication?.id || clientSecret.length < 1) return;

    try {
      const stripeResponse = await stripe.retrieveSetupIntent(clientSecret);

      if (stripeResponse.setupIntent) {
        setSetupIntent(stripeResponse.setupIntent);
        return;
      }

      if (stripeResponse.error.code === 'resource_missing') {
        dispatch(createSetupIntent(insuranceApplication.id));
      } else {
        dispatch(
          notificationSlice.actions.setError({
            message: text('payment.loadFormError'),
          })
        );
      }
    } catch (err) {
      dispatch(
        notificationSlice.actions.setError({
          message: text('payment.loadFormError'),
        })
      );
    }
  }, [clientSecret, dispatch, insuranceApplication?.id, stripe]);

  useEffect(() => {
    if (!insuranceApplication?.id || !stripe) return;
    dispatch(fetchClientSecret(insuranceApplication.id));
  }, [dispatch, insuranceApplication?.id, stripe]);

  useEffect(() => {
    if (clientSecret.length < 1) return;
    void retrieveSetupIntent();
  }, [clientSecret, retrieveSetupIntent]);

  useEffect(() => {
    if (!insuranceApplication?.id || !setupIntent || hasSubmitted || !stripe) return;

    switch (setupIntent.status) {
      case 'succeeded':
        // Skip to submission if the setup has already succeeded
        onNextClick();
        setHasSubmitted(true);
        break;

      case 'processing':
        // For credit card payments, the processing time should be short enough. In case we hit a processing status, we
        // simply try to re-fetch the setup intent after a while and hope that the `processing` status will be gone by then
        break;

      case 'requires_payment_method':
        // Proceed with displaying the form
        break;

      case 'requires_confirmation':
      case 'requires_action':
      // We don't handle the above two statuses since they should already be bypassed when we confirm a credit card
      // using Stripe's `confirmCardSetup` method. In case we hit those statuses, we cancel the previous setup intent
      // and re-create a new one.
      // eslint-disable-next-line no-fallthrough
      case 'canceled':
      default:
        // We can only add a payment method if the setup intent status is `requires_payment_method`
        // We need to create a new setup intent if otherwise (`canceled` in most cases) to proceed
        // See: https://stripe.com/docs/payments/intents#intent-statuses
        dispatch(createSetupIntent(insuranceApplication.id));
    }
  }, [dispatch, hasSubmitted, insuranceApplication?.id, onNextClick, setupIntent, stripe]);

  useEffect(() => {
    if (!setupIntent || setupIntent.status !== 'processing') return;

    // `processing` is a transitional status between `require_payment_method` and `succeeded`. Therefore, when this is
    // the setup intent status, we poll (refresh) the setup intent until we receive a new status
    // We use `setTimeout` here because `setupIntent` will always trigger this `useEffect` since it's an object, which
    // is replaced by a new reference after we polled the setup intent.
    // TODO: DEV-8496 Improve polling
    setPollingTimeoutId((timeoutId) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      return setTimeout(retrieveSetupIntent, 3000);
    });
  }, [retrieveSetupIntent, setupIntent]);

  useEffect(() => {
    if (!pollingTimeoutId) return;
    return () => clearTimeout(pollingTimeoutId);
  }, [pollingTimeoutId]);

  const hideForm = !setupIntent || setupIntent.status !== 'requires_payment_method';

  return <PaymentView hideForm={hideForm} onNextClick={onNextClick} onPreviousClick={onPreviousClick} />;
}
