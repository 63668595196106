export default {
  title: 'Voici ce que je recommande pour votre couverture',
  subtitle: '',
  simpleQuoterTitle: 'Ajustez votre assurance Vie',
  simpleQuoterSubtitle: '',
  recommendedCoverage: {
    label: 'Le montant de la couverture',
    infoTitle: 'Couverture recommandée',
    infoText: 'Cette recommandation est basée sur : Dettes + Éducation + Besoins de revenus annuels - Épargne',
  },
  selectedCoverage: {
    label: 'Votre couverture est de',
    sliderAmountText: "Déplacer le curseur pour connaitre l'impact de la couverture sur votre prime mensuelle",
    simpleQuoterSliderAmountText:
      'Déplacez le curseur pour choisir un montant de couverture. Le montant que vous choisissez aura un impact sur votre prime mensuelle. ',
  },
  NA: 'ND',
  perMonth: '/ mois',
  detailsButtonLabel: 'Détails',
  applyButtonLabel: 'Souscrire',
  applyThroughAdvisorButtonLabel: 'Souscrire avec un conseiller',
  advisorWillBeInTouch: "Je vous contacterai très prochainement pour vous expliquer vos options d'assurance. Merci!",
  premiumDisclosure:
    "Les montants des primes sont seulement indiqués pour l'estimation de devis. L'assureur n'est pas responsable des éventuelles erreurs affichées. L'assureur déterminera les primes requises après analyse de la demande. La prime requise sera indiquée dans la police émise.",
  policyDetails: {
    title: 'Détails de la police',
    link: 'Lire la <a href="{{url}}" target="_blank" rel="noopener noreferrer">Police d\'assurance</a> complète',
  },
  almostCompleted: "Vous n'êtes qu'à quelques minutes de terminer votre soumission.",
  coverage: 'couverture',
  warning: {
    warningTitle: 'Attention',
    recommendedCoverageChange:
      'Le montant de la couverture est différent de la couverture recommandée. Pour en savoir plus, prenez contact avec un conseiller.',
    coverageOverLimitOfProduct:
      'Le montant de couverture recommandé est supérieur au montant maximum pour ce produit. Pour en savoir plus, prenez contact avec un conseiller.',
  },
  updatedPremium: 'Prime mise à jour à {{amount}} / mois',
  error: {
    fetchQuotes: 'Erreur lors de la récupération des quotes',
    fetchQuestionnaire: 'Erreur lors de la récupération du questionnaire',
    fetchSummary: 'Erreur lors de la récupération du résumé',
    submission: "Erreur lors de la soumission de l'application",
    updatePremium: 'Erreur lors de la mise à jour de la prime',
    getAddons: 'Erreur lors de la récupération des addons',
  },
  getInTouch: 'Prenez contact',
  morning: 'Matin, 9h à 12h',
  day: 'Après-midi, 12h à 18h',
  evening: 'Soirée, 18h à 20h',
  productSummary: {
    intro: "Récapitulons l'assurance {{productName}} de {{carrierName}}.",
  },
  otherProducts:
    "Il se peut que nous ayons d'autres produits ou montants de couverture qui vous conviennent mieux. Pour en savoir plus, veuillez contacter nous.",
  addons: {
    updatedPremium: {
      description: 'Votre nouvelle prime est maintenant de:',
    },
    free: 'Gratuit',
    alreadyInclude: 'Ces options sont déjà comprises dans votre police',
    includedOption: "L'option est déjà comprise dans votre police",
    purchasableOption: 'Ajouter cette option',
    forMoreInformation: 'En savoir plus',
  },
  applicationDetails: "Détails sur l'application",
  yes: 'Oui',
  no: 'Non',
  medicalExam: 'Examen médical',
  untilAge: "jusqu'à l'âge",
  coverageFlexibility: 'Flexibilité de couverture',
  renewable: 'Renouvelable',
  convertible: 'Convertible',
  options: 'Options',
  simpleQuoterCalculate: 'Calculer',
  simpleQuoterUseCalculator: 'Utilisez notre calculatrice pour estimer le montant de couverture qui vous convient',
};
