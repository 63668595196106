import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import { CheckBox as SharedCheckbox } from '@breathelife/ui-components';

export const Container = styled.div`
  color: ${(props) => props.theme.colors.primary[10]};
`;

export const BaseContent = styled(Box)`
  border-radius: 8px;
  padding: 16px 24px;
  box-shadow: 0 20px 32px -8px ${(props) => props.theme.colors.grey[40]};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    padding: 16px;
  }
`;

export const LargeContent = styled(BaseContent)`
  margin-bottom: 24px;
  background-color: ${(props) => props.theme.colors.primary.default};
  color: ${(props) => props.theme.colors.primary[10]};
`;

export const IncludedLargeContent = styled(BaseContent)`
  color: ${(props) => props.theme.colors.primary[90]};
  padding: 20px 16px;
  margin-bottom: 24px;
  background-color: ${(props) => props.theme.colors.grey[0]};
`;

export const SmallContent = styled(BaseContent)`
  background-color: ${(props) => props.theme.colors.grey[20]};
  padding: 16px;
  box-shadow: 0 0 0 0;
`;

export const PricingSelection = styled(Box)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    display: block;
  }
  display: flex;
  justify-content: space-between;
`;

export const Subtitle = styled(Box)`
  font-size: 14px;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 12px;
  font-weight: lighter;
`;

export const IncludedSubtitle = styled(Subtitle)`
  font-size: 14px;
`;

export const InformationLink = styled.a`
  font-size: 14px;
  line-height: 12px;
  font-weight: 200;
  color: inherit;
  :hover {
    color: ${(props) => props.theme.colors.grey[0]};
    text-decoration: underline;
  }
`;

export const IncludedInformationLink = styled(InformationLink)`
  color: ${(props) => props.theme.colors.secondary['default']};
  :hover {
    color: ${(props) => props.theme.colors.secondary['default']};
  }
`;

export const PricingText = styled(Box)`
  color: ${(props) => props.theme.colors.grey[90]};
  font-size: 14px;
  font-weight: bold;
  height: 40px;
`;

export const IncludedCheckboxContainer = styled(Box)`
  cursor: default;
  justify-content: start;
`;

export const CheckboxContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[0]};
  display: flex;
  justify-content: start;
  border-radius: 8px;
  padding: 0 16px 8px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    margin-top: 15px;
  }
`;

export const Checkbox = styled(SharedCheckbox)`
  text-transform: uppercase;
  white-space: nowrap;
  &&& {
    margin: 14px 0px 0px 10px;
    input:checked + label:before {
      background-color: ${(props) => props.theme.colors.secondary.default} !important;
    }
  }
  &&&&& {
    label {
      padding: 0px;
      color: ${(props) => props.theme.colors.secondary[60]};
    }
  }
`;

export const RoundCheckbox = styled(SharedCheckbox)`
  &&&&& {
    margin: 17px 0px 0px 0px;
    input:checked + label:before {
      background-color: ${(props) => props.theme.colors.secondary.default} !important;
      border-radius: 25px !important;
    }
    label {
      padding: 0px;
      color: ${(props) => props.theme.colors.primary[60]};
      font-weight: bold;
    }
    label:before {
      border: none;
    }
  }
`;

export const Separator = styled(Box)`
  margin: 30px 0;
  background-color: ${(props) => props.theme.colors.secondary.default};
  height: 3px;
  width: 50px;
  border-radius: 7px;
`;

export const MonthText = styled.span`
  font-size: 20px;
  font-weight: normal;
`;

export const PricingNumberText = styled(Box)`
  font-size: 37px;
  font-weight: bold;
  height: 40px;
`;
