import React from 'react';
import { useTheme } from 'styled-components';

import { AddonsOptionUpsell, Language } from '@breathelife/types';
import { SharedTheme, Text, Title } from '@breathelife/ui-components';

import { localize, text } from '../../Localization/Localizer';
import { RoundCheckbox, IncludedInformationLink, IncludedLargeContent, Separator, Subtitle } from './Styles';

type Props = {
  language: Language;
  addon: AddonsOptionUpsell;
};

export function DefaultAddonView(props: Props): React.ReactElement {
  const { addon, language } = props;

  const theme = useTheme() as SharedTheme;

  return (
    <IncludedLargeContent key={addon.id}>
      <Text variant='big-number'>{text('product.addons.free')}</Text>
      <Title level={4} color={theme.colors.primary[60]}>
        <RoundCheckbox id='addons-included-option' checked label={text('product.addons.alreadyInclude')} />
      </Title>

      <Subtitle>{localize(addon.title, language)}</Subtitle>
      <Subtitle>{localize(addon.subtitle, language)}</Subtitle>
      <IncludedInformationLink href={addon.link} target='_blank' rel='noreferrer noopener'>
        {text('product.addons.forMoreInformation')}
      </IncludedInformationLink>
      <Separator />
    </IncludedLargeContent>
  );
}
