import { Box } from '@material-ui/core';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethodCreateParams, SetupIntent, StripeCardNumberElement } from '@stripe/stripe-js';
import _ from 'lodash';
import React, { FormEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IconName } from '@breathelife/types';
import {
  defaultStripeElementsState,
  Information,
  Loader,
  NavigationButtons,
  StripeCardDetailsForm,
  StripeElementsState,
  validateCreditCardDetailsForm,
  Icon,
} from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { CenteredLayout } from '../../Layouts/Centered/Layout';
import { shortLocale, text } from '../../Localization/Localizer';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { StyledNextButton } from './Styles';

type PaymentViewProps = {
  hideForm?: boolean;
  onNextClick: () => void;
  onPreviousClick: () => void;
};

export function PaymentView(props: PaymentViewProps): React.ReactElement {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { isLoading, clientSecret } = useCxSelector((store) => store.consumerFlow.payment);
  const { insuranceApplication } = useCxSelector((store) => store.consumerFlow.insuranceApplication);

  const { hideForm, onNextClick, onPreviousClick } = props;

  const [stripeElementsState, setStripeElementsState] = useState<StripeElementsState>(defaultStripeElementsState);
  const [validationErrors, setValidationErrors] = useState<PaymentMethodCreateParams.BillingDetails>({});
  const [billingDetails, setBillingDetails] = useState<PaymentMethodCreateParams.BillingDetails>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionSucceeded, setSubmissionSucceeded] = useState<boolean>(false);

  const validateForm = useCallback(() => {
    const validationErrors = validateCreditCardDetailsForm(stripeElementsState, billingDetails);
    setValidationErrors(validationErrors);
    return validationErrors;
  }, [billingDetails, stripeElementsState]);

  const stripeConfirmCardSetup = useCallback(
    async (clientSecret: string): Promise<SetupIntent | null> => {
      if (!stripe || !elements) {
        throw new Error('Stripe Elements not initialized.');
      }

      const cardNumberElement = elements.getElement(CardNumberElement) as StripeCardNumberElement | null;
      if (!cardNumberElement) {
        throw new Error('Card number element cannot be found.');
      }

      const validationErrors = validateForm();
      if (!_.isEmpty(validationErrors)) {
        return null;
      }

      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: billingDetails,
        },
      });

      if (result.error || result.setupIntent.status !== 'succeeded') {
        setIsSubmitting(false);
        dispatch(
          notificationSlice.actions.setError({
            message: text('payment.submitPaymentError'),
          })
        );
        return null;
      }

      return result.setupIntent;
    },
    [billingDetails, dispatch, elements, stripe, validateForm]
  );

  const onSubmitForm = useCallback(
    async (event?: FormEvent) => {
      event?.preventDefault();

      if (isSubmitting || submissionSucceeded || !stripe || !elements || !clientSecret || !insuranceApplication?.id)
        return;

      setSubmissionSucceeded(false);
      setIsSubmitting(true);

      const setupIntent = await stripeConfirmCardSetup(clientSecret);

      if (!setupIntent) {
        setIsSubmitting(false);
        return;
      }

      setIsSubmitting(false);
      setSubmissionSucceeded(true);
      onNextClick();
    },
    [
      isSubmitting,
      submissionSucceeded,
      stripe,
      elements,
      clientSecret,
      insuranceApplication?.id,
      stripeConfirmCardSetup,
      onNextClick,
    ]
  );

  return (
    <CenteredLayout
      title={text('payment.title')}
      subtitle={text('payment.subtitle')}
      offsetContent={<Icon name={IconName.payment} />}
      isLoading={isLoading || !stripe || !elements || !clientSecret || !!hideForm}
      loadingContent={{
        title: '',
        subtitle: '',
      }}
    >
      <Box mt={2}>
        <form onSubmit={onSubmitForm} noValidate={true}>
          <StripeCardDetailsForm
            disabled={isSubmitting || submissionSucceeded}
            language={shortLocale()}
            onStripeElementsChanged={setStripeElementsState}
            onBillingDetailsChanged={setBillingDetails}
            validationErrors={validationErrors}
          />
        </form>
      </Box>
      <Box mt={3}>
        <Information text={text('payment.informationText')} />
      </Box>
      <Box>
        <NavigationButtons
          onPreviousClick={onPreviousClick}
          hidePrevious={false}
          hideNext={false}
          nextButton={
            <StyledNextButton onClick={onSubmitForm}>
              {isSubmitting && <Loader color='white' />}
              {!isSubmitting && !submissionSucceeded && text('payment.continueButtonText')}
              {!isSubmitting && submissionSucceeded && (
                <Icon name={IconName.confirmationOutlined} color={{ primary: 'white' }} />
              )}
            </StyledNextButton>
          }
          onNextClick={onSubmitForm}
        />
      </Box>
    </CenteredLayout>
  );
}
