import React, { useContext } from 'react';

import { CarrierContext } from '../../Context/CarrierContext';
import { text } from '../../Localization/Localizer';

export type LogoProps = {
  logoSrc?: string;
  className?: string;
};

export function CarrierLogo(props: LogoProps) {
  const { carrierInfo } = useContext(CarrierContext);

  return (
    <img
      className={props.className}
      src={props.logoSrc ?? carrierInfo.logo}
      alt={text('logo', { companyName: carrierInfo.companyName })}
    />
  );
}
