import React, { useMemo, useContext } from 'react';

import {
  TransactionFlowDesktopHeader,
  TransactionFlowMobileHeader,
  ResponsiveProps,
  LegalLink,
} from '@breathelife/ui-components';

import { CarrierLogo } from '../../Components/CarrierLogo/CarrierLogo';
import { CarrierContext } from '../../Context/CarrierContext';
import { text } from '../../Localization/Localizer';

type Props = {
  title: string;
  progress: number;
  legalLinks: LegalLink[];
  collapseMobileHeader: boolean;
  showScheduleCallAction: boolean;
} & ResponsiveProps;

export function TransactionFlowHeader(props: Props): React.ReactElement {
  const { carrierCustomizations } = useContext(CarrierContext);
  const { Header, MobileHeaderInfoView } = carrierCustomizations;

  const MobileHeader = useMemo(
    () => (
      <TransactionFlowMobileHeader
        progress={props.progress}
        legalLinks={props.legalLinks}
        collapseHeader={props.collapseMobileHeader}
        title={props.title}
        infoView={!!props.title && <MobileHeaderInfoView />}
        logo={<CarrierLogo />}
      >
        <Header showScheduleCallAction={props.showScheduleCallAction} />
      </TransactionFlowMobileHeader>
    ),
    [props.progress, props.legalLinks, props.collapseMobileHeader, props.title, props.showScheduleCallAction]
  );

  const DesktopHeader = useMemo(
    () => (
      <TransactionFlowDesktopHeader
        progress={props.progress}
        progressText={text('header.completion', { progress: props.progress })}
        legalLinks={props.legalLinks}
      >
        <Header showScheduleCallAction={props.showScheduleCallAction} />
      </TransactionFlowDesktopHeader>
    ),
    [props.progress, props.legalLinks, props.showScheduleCallAction]
  );

  return props.isMobile ? MobileHeader : DesktopHeader;
}
