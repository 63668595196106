import { createGlobalStyle, FlattenSimpleInterpolation } from 'styled-components';

import { iOSSupport } from '@breathelife/ui-components';

import { normalizer } from './Normalizer';

type GlobalStyleProps = {
  carrierStyle?: FlattenSimpleInterpolation;
};

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  ${iOSSupport}
  ${normalizer}
  ${(props) => props.carrierStyle ?? ''};
`;
