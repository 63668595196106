import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useContext } from 'react';

import config from '@breathelife/config/frontend';
import { FieldGenerator, RenderContext, StyleVariant } from '@breathelife/field-generator';
import { getAllQuestions, QuestionnaireEngine, RenderingQuestion } from '@breathelife/questionnaire-engine';

import { Spinner } from '../../Components/Spinner';
import { CarrierContext } from '../../Context/CarrierContext';
import { useStep } from '../../Hooks/useStep';
import { BaseHeader } from '../../Layouts/Centered/Header';
import { shortLocale, text } from '../../Localization/Localizer';
import { StepProps } from '../../Models/Question';
import { Card, CardContent, CardSubtitle, CardTitle, Content, MainImage, StartButton } from './Styles';

type GenericLinkLandingProps = {
  mainImage?: string;
  questionnaireEngine: QuestionnaireEngine;
  displayErrors?: boolean;
  submitAnswer: (answer: any) => void;
} & StepProps;

export function GenericLinkLanding(props: GenericLinkLandingProps): React.ReactElement {
  const {
    questionnaireEngine,
    displayErrors = false,
    submitAnswer,
    onAnswerComplete,
    onInfoIconClick,
    onError,
    answers,
    isLoading,
  } = props;

  const { renderingQuestionnaire, renderingStep, onSubmit, onAnswerChange } = useStep(
    questionnaireEngine,
    displayErrors,
    submitAnswer,
    answers
  );

  const language = shortLocale();
  const { iconMap, images } = useContext(CarrierContext);

  const landingPageImage = _.get(images, 'genericPublicLink.landingPageImage') ?? '';

  const questions = getAllQuestions(renderingQuestionnaire);

  const title = text('genericLinkLanding.title') || renderingStep.title;
  const subtitle = text('genericLinkLanding.subtitle') || renderingStep.text;

  const onSubmitForm: (event: React.FormEvent<HTMLFormElement>) => void = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  if (isLoading) return <Spinner />;

  return (
    <Box component='main' width='100%'>
      <BaseHeader hideScheduleCall hideProgress />
      <Card>
        <MainImage
          $image={landingPageImage}
          $displayMobile={config.get('ui.displayGenericLinkLandingPageImage.mobile') ?? true}
        />
        <CardContent>
          <Content>
            <CardTitle level={1} center>
              {title}
            </CardTitle>
            <CardSubtitle level={4} center>
              {subtitle}
            </CardSubtitle>
            <form onSubmit={onSubmitForm} noValidate={true}>
              {questions.map((question: RenderingQuestion) => (
                <FieldGenerator
                  key={question.id}
                  context={RenderContext.web}
                  question={question}
                  locale={language}
                  onAnswerChange={onAnswerChange}
                  onAnswerComplete={onAnswerComplete}
                  styleVariant={StyleVariant.consumer}
                  iconMap={iconMap}
                  onInfoIconClick={onInfoIconClick}
                  onError={onError}
                />
              ))}
              <StartButton type='submit'>
                <span>{text('genericLinkLanding.submitButton')}</span>
              </StartButton>
            </form>
          </Content>
        </CardContent>
      </Card>
    </Box>
  );
}
