import _ from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { Loader, RunningText, Title } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';

const Container = styled.div`
  min-height: 400px;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    margin-top: 32px;
  }
`;

const Subtitle = styled(RunningText)`
  color: ${(props) => props.theme.colors.grey[90]};
  margin-top: 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    margin-top: 24px;
  }
`;

const TextContainer = styled.div`
  margin-top: 32px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    margin-top: 56px;
  }
`;

const LoaderContainer = styled.div`
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    svg {
      width: 83px;
      height: 83px;
    }
  }
`;

export type LoadingContentProps = {
  title: string;
  subtitle: string;
  image?: React.ReactElement;
};

export function LoadingContent(props: LoadingContentProps): React.ReactElement {
  const { title, subtitle } = props;
  const { images } = useContext(CarrierContext);

  const planFinderImage = _.get(images, 'planFinder.loading');
  const loadingImage = planFinderImage ? <img src={planFinderImage} alt='' /> : props.image;

  return (
    <Container>
      <LoaderContainer>{loadingImage ?? <Loader />}</LoaderContainer>
      <TextContainer>
        <Title level={1} center={true}>
          {title}
        </Title>
        <Subtitle center={true}>{subtitle}</Subtitle>
      </TextContainer>
    </Container>
  );
}
