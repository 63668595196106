import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/react-tracking';
import { Language } from '@breathelife/types';

import { useCxSelector } from '../../Hooks/useCxSelector';
import * as communicationOperations from '../../Redux/Communication/CommunicationOperations';
import { BlockingView } from './BlockingView';

type Props = {
  language: Language;
};

export function BlockingContainer(props: Props): React.ReactElement {
  const insuranceApplication = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication);

  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!insuranceApplication) return;

    dispatch(communicationOperations.getCommunicationPreferences(insuranceApplication.id));
  }, [dispatch, insuranceApplication]);

  useEffect(() => {
    if (insuranceApplication?.id) {
      TypewriterTracking.userDeemedIneligible({
        hashedId: hash(insuranceApplication.id),
      });
    }
  }, [insuranceApplication]);

  return <BlockingView applicationAssigneeImage={applicationAssignee?.picture} language={props.language} />;
}
