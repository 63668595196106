import { InsuranceScopes } from '@breathelife/types';

export default {
  advisorGreeting: "Hi, I'm {{advisorName}}. ",
  interlocutorGreeting: 'Hi, we are {{interlocutorName}}',
  startFlow: "Let's get started",
  advisorTitle: 'Financial advisor',
  [InsuranceScopes.life]: {
    welcomeTextTitle: "Let's find the right life insurance plan for you.",
    welcomeTextSubtitle: {
      single:
        "Let's take the next few minutes to answer some questions together so that I can suggest the right financial protection for your family’s peace of mind.",
      plural:
        "Let's take the next few minutes to answer some questions together so that we can suggest the right financial protection for your family’s peace of mind.",
    },
  },
  [InsuranceScopes.retirement]: {
    welcomeTextTitle: 'Are you prepared for retirement?',
    welcomeTextSubtitle: {
      single:
        "Life is full of unknowns and now is the time to consider smart ways to save for your retirement. Take control of your savings with safe and flexible options. Let's start exploring your retirement readiness and come up with a personalized retirement solution.",
      plural:
        "Life is full of unknowns and now is the time to consider smart ways to save for your retirement. Take control of your savings with safe and flexible options. Let's start exploring your retirement readiness and come up with a personalized retirement solution.",
    },
  },
};
