import { InsuranceScopes } from '@breathelife/types';

export default {
  advisorGreeting: 'Bonjour, je suis {{advisorName}}. ',
  interlocutorGreeting: 'Bonjour, nous sommes {{interlocutorName}}',
  startFlow: 'Allons-y!',
  advisorTitle: 'Conseiller financier',
  [InsuranceScopes.life]: {
    welcomeTextTitle: "Trouvons le plan d'assurance vie qui vous convient",
    welcomeTextSubtitle: {
      single:
        "Prenons les prochaines minutes pour répondre ensemble à quelques questions afin que je puisse vous proposer la bonne protection financière pour la tranquillité d'esprit de votre famille.",
      plural:
        "Prenons les prochaines minutes pour répondre ensemble à quelques questions afin que nous puissions vous proposer la bonne protection financière pour la tranquillité d'esprit de votre famille.",
    },
  },
  [InsuranceScopes.retirement]: {
    welcomeTextTitle: 'Êtes-vous prêt pour la retraite?',
    welcomeTextSubtitle: {
      single:
        "La vie est pleine d'inconnues et il est maintenant temps d'envisager des moyens intelligents d'épargner pour votre retraite. Prenez le contrôle de vos économies grâce à des options sûres et flexibles. Commençons par explorer votre préparation à la retraite et imaginons une solution de retraite personnalisée.",
      plural:
        "La vie est pleine d'inconnues et il est maintenant temps d'envisager des moyens intelligents d'épargner pour votre retraite. Prenez le contrôle de vos économies grâce à des options sûres et flexibles. Commençons par explorer votre préparation à la retraite et imaginons une solution de retraite personnalisée.",
    },
  },
};
