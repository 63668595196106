import React from 'react';

import { FieldGenerator, RenderContext, StyleVariant } from '@breathelife/field-generator';
import {
  getAllQuestions,
  OnAnswerChange,
  RenderingQuestion,
  RenderingQuestionnaire,
} from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';
import { NavigationButtons } from '@breathelife/ui-components';

import { text } from '../../Localization/Localizer';
import { StepProps } from '../../Models/Question';
import { Props as StepPageProps } from '../../Pages/StepPage/StepPage';

export type Props = Partial<StepProps> &
  Partial<StepPageProps> & {
    renderingQuestionnaire: RenderingQuestionnaire;
    renderingStepId: string;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    onAnswerChange: OnAnswerChange;
    onAnswerComplete: (fieldId: string) => void;
    onInfoIconClick?: () => void;
    language: Language;
    children?: React.ReactNode;
    nextStepButtonText?: string;
    iconMap?: Record<string, string>;
    isNextBtnLoading: boolean;
  };

export function FormView(props: Props): React.ReactElement | null {
  const {
    renderingQuestionnaire,
    onSubmit,
    onAnswerComplete,
    onBackButtonClick,
    onInfoIconClick,
    onError,
    onAnswerChange,
    language,
    iconMap,
    stepMetadata,
    landingStepsIds,
    renderingStepId,
    nextStepButtonText,
    isNextBtnLoading,
  } = props;

  const questions = getAllQuestions(renderingQuestionnaire);

  return (
    <form onSubmit={onSubmit} noValidate={true}>
      {questions.map((question: RenderingQuestion) => (
        <FieldGenerator
          key={question.id}
          context={RenderContext.web}
          question={question}
          locale={language}
          onAnswerChange={onAnswerChange}
          onAnswerComplete={onAnswerComplete}
          styleVariant={StyleVariant.consumer}
          iconMap={iconMap}
          onInfoIconClick={onInfoIconClick}
          onError={onError}
        />
      ))}
      {props.children}
      <NavigationButtons
        onPreviousClick={onBackButtonClick ? onBackButtonClick : () => {}}
        hidePrevious={!!landingStepsIds?.includes(renderingStepId)}
        hideNext={false}
        nextButtonText={nextStepButtonText || stepMetadata?.continueButtonText || text('continue')}
        onNextClick={() => {}}
        isNextBtnLoading={isNextBtnLoading}
      />
    </form>
  );
}
