import { createMuiTheme as muiThemeCreator, Theme } from '@material-ui/core/styles';

import { createBaseMuiTheme, SharedTheme } from '@breathelife/ui-components';

const fontsFamilies = [
  '"-apple-system"', // "San Francisco", macOS / iOS default
  '"BlinkMacSystemFont"', // macOS / iOS default (Chrome only)
  '"Helvetica Neue"', // macOS / iOS default (older OSes)
  '"Segoe UI"', // Windows default
  '"Roboto"', // Android default
  '"sans-serif"',
].join(',');

export function createMuiTheme(theme: SharedTheme): Theme {
  return muiThemeCreator({
    ...createBaseMuiTheme(theme),
    breakpoints: {
      // Approximate to our internal breakpoints
      values: {
        xs: 0,
        sm: 300,
        md: 768,
        lg: 1024,
        xl: 1600,
      },
    },
    palette: {
      primary: { main: theme.colors.primary.default ?? theme.colors.primary[50] },
      secondary: { main: theme.colors.secondary.default ?? theme.colors.secondary[50] },
    },
    typography: {
      fontSize: 16,
      // Use system font instead of using Roboto on all platforms
      fontFamily: fontsFamilies,
      button: {
        fontSize: 13,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true, // Disable ripple animation on click
      },
    },
    overrides: {
      MuiInputBase: {
        input: {
          letterSpacing: 0.2,
        },
      },
      MuiCircularProgress: {
        colorPrimary: {
          margin: '1em',
          color: `${theme.colors.primary.default} !important`,
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: theme.colors.grey[50],
        },
        root: {
          '&$error $notchedOutline': {
            borderColor: theme.colors.text.error,
          },
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: theme.colors.grey[60],
          },
          '&$focused $notchedOutline': {
            borderWidth: 3,
            borderColor: theme.colors.hyperlink,
          },
          '&$focused $input': {
            caretColor: theme.colors.primary[40],
          },
        },
      },
    },
  });
}
