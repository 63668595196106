import { push as routerPush } from 'connected-react-router';
import queryString from 'query-string';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, withRouter } from 'react-router';

import { InsuranceScopes } from '@breathelife/types';
import { ResponsiveProps } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { isValidScope } from '../../Helpers/InsuranceScopes';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { AccessTokenMethod } from '../../Models/AccessTokenMethod';
import { ApplicationAssignee } from '../../Models/ApplicationAssignee';
import { ModalType } from '../../Models/Layout';
import Urls from '../../Navigation/Urls';
import { fetchApplicationAssignee } from '../../Redux/ApplicationAssignee/ApplicationAssigneeOperations';
import { communicationSlice } from '../../Redux/Communication/CommunicationSlice';
import {
  startFlow as startFlowOperation,
  StartFlowOptions,
} from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import { layoutSlice } from '../../Redux/Layout/LayoutSlice';
import * as stepOperations from '../../Redux/Step/StepOperations';
import { HomePage } from './HomePage';

export type HomePageProps = {
  startFlow: (options: StartFlowOptions) => void;
  submitAnswer: (stepId: string, answer: any) => void;
  applicationAssignee?: ApplicationAssignee;
  isLoading: boolean;
  onScheduleButtonClick?: () => void;
  scopes?: string[];
} & ResponsiveProps;

function Container(): React.ReactElement | null {
  const location = useLocation();

  const {
    carrierCustomizations: { HomePage: CustomHomePage },
  } = useContext(CarrierContext);
  const application = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication);
  const { features } = useContext(CarrierContext);

  const {
    token,
    method,
    insuranceScopes: insuranceScopesQueryParam,
    id,
  } = queryString.parse(location.search) as Record<string, string | undefined>;
  const insuranceScopes = useMemo(() => {
    return insuranceScopesQueryParam?.split(',') ?? application?.insuranceScopes;
  }, [application, insuranceScopesQueryParam]);
  const insuranceScope = insuranceScopes?.[0]; // currently we only support single scopes
  const isValidInsuranceScope = isValidScope(insuranceScope);

  const [isPageReady, setIsPageReady] = useState(false);

  const dispatch = useDispatch();
  const appId = useCxSelector((state) => state.consumerFlow.insuranceApplication?.insuranceApplication?.id);
  const basePath = useCxSelector((store) => store.consumerFlow.navigation.basePath);
  const { assignee: applicationAssignee, isLoading: isApplicationAssigneeLoadingState } = useCxSelector(
    (store) => store.consumerFlow.applicationAssignee
  );

  const isValidPrivateLink = token && (method === AccessTokenMethod.privateLink || method === AccessTokenMethod.email);
  const isValidPublicLink = token && method === AccessTokenMethod.publicLink && isValidInsuranceScope;
  const isValidCobrowsingLink = token && method === AccessTokenMethod.coBrowsing;

  const startFlow = useCallback(
    (options: StartFlowOptions) => {
      const {
        token,
        method,
        insuranceScopes: insuranceScopesQueryParam,
        id,
      } = queryString.parse(location.search) as Record<string, string | undefined>;
      const insuranceScopes = insuranceScopesQueryParam?.split(',') ?? application?.insuranceScopes;

      dispatch(
        startFlowOperation({
          ...options,
          appId: id,
          token,
          method,
          insuranceScopes,
        })
      );
    },
    [application, dispatch, location.search]
  );

  useEffect(() => {
    if (!method) return;
    if (method === AccessTokenMethod.publicLink || method === AccessTokenMethod.privateLink) {
      dispatch(communicationSlice.actions.setHasAppliedThroughAdvisor(false));
    }
  }, [dispatch, method]);

  useEffect(() => {
    if (
      !features.selfServeApplication?.enabled &&
      !appId &&
      !id &&
      !(isValidPrivateLink || isValidPublicLink || isValidCobrowsingLink)
    ) {
      // If there is an existing application or all of the token, method and insuranceScope values are valid
      // we can continue to the homepage. Otherwise, we're dealing with an unsupported insurance scope so 404

      dispatch(routerPush(Urls.fourOhFour(basePath)));
    }
  }, [
    appId,
    basePath,
    dispatch,
    isValidPrivateLink,
    isValidPublicLink,
    isValidCobrowsingLink,
    id,
    features.selfServeApplication?.enabled,
    token,
  ]);

  useEffect(() => {
    if (isApplicationAssigneeLoadingState) {
      return;
    }

    if (applicationAssignee) {
      setIsPageReady(true);
      return;
    }

    if (isValidPublicLink || isValidPrivateLink || isValidCobrowsingLink || appId) {
      dispatch(fetchApplicationAssignee(appId, token, method as AccessTokenMethod));
    } else {
      setIsPageReady(true);
    }
  }, [
    dispatch,
    applicationAssignee,
    isApplicationAssigneeLoadingState,
    appId,
    token,
    method,
    isValidPublicLink,
    isValidCobrowsingLink,
    isValidPrivateLink,
  ]);

  if (!isPageReady) return null;

  if (CustomHomePage) {
    return <ResponsiveHomePageComponent startFlow={startFlow} insuranceScopes={insuranceScopes} />;
  } else {
    return (
      <HomePageComponent
        isValidInsuranceScope={isValidInsuranceScope}
        startFlow={startFlow}
        insuranceScopes={insuranceScopes}
      />
    );
  }
}

function ResponsiveHomePageComponent(props: { startFlow: any; insuranceScopes: any }): React.ReactElement | null {
  const dispatch = useDispatch();
  const isApplicationLoading = useCxSelector((store) => store.consumerFlow.insuranceApplication.isLoading);
  const isStepLoading = useCxSelector((store) => store.consumerFlow.step.isLoading);
  const {
    carrierCustomizations: { HomePage: ResponsiveHomePage },
  } = useContext(CarrierContext);
  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);

  const onScheduleCallButtonClick = useCallback(() => {
    dispatch(layoutSlice.actions.setModalState({ modalState: { isOpen: true, type: ModalType.contact } }));
  }, [dispatch]);

  const submitAnswer = useCallback(
    (stepId: string, answer: any) => {
      dispatch(stepOperations.answerQuestionAndNavigate(stepId, answer));
    },
    [dispatch]
  );

  if (ResponsiveHomePage === null) {
    return null;
  }

  return (
    <ResponsiveHomePage
      submitAnswer={submitAnswer}
      startFlow={props.startFlow}
      applicationAssignee={applicationAssignee}
      isLoading={isApplicationLoading || isStepLoading}
      onScheduleButtonClick={onScheduleCallButtonClick}
      scopes={props.insuranceScopes}
    />
  );
}

function HomePageComponent(props: {
  startFlow: any;
  insuranceScopes: any;
  isValidInsuranceScope: any;
}): React.ReactElement | null {
  const isApplicationLoading = useCxSelector((store) => store.consumerFlow.insuranceApplication.isLoading);
  const isStepLoading = useCxSelector((store) => store.consumerFlow.step.isLoading);
  const { landingStepsIds } = useCxSelector((store) => store.consumerFlow.configuration);
  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);

  return (
    <HomePage
      // TODO: pass allowSelfServe in the config features
      onCtaClick={() => props.startFlow({ firstStepId: landingStepsIds[0], allowSelfServe: false })}
      applicationAssignee={applicationAssignee}
      isLoading={isApplicationLoading || isStepLoading}
      insuranceScope={props.isValidInsuranceScope ? (props.insuranceScopes as InsuranceScopes) : InsuranceScopes.life}
    />
  );
}

export const HomePageContainer = withRouter(Container);
