import { push as routerPush } from 'connected-react-router';
import { Dispatch } from 'redux';

import { LoadingPageMessages, navigationSlice } from '@breathelife/redux';

import Urls from '../../Navigation/Urls';
import { ConsumerFlowStore } from '../Store';

let loadingPageSetTimeoutPtr: ReturnType<typeof setTimeout>;

export const hideLoadingPage = () => async (dispatch: Dispatch) => {
  dispatch(navigationSlice.actions.setLoadingPage({ isVisible: false }));
  clearTimeout(loadingPageSetTimeoutPtr);
};

export const navigate =
  (navigateTo: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    await hideLoadingPage()(dispatch);

    dispatch(routerPush(navigateTo));
  };

export const navigateToQuestion =
  (questionId: string) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    const { basePath } = store().consumerFlow.navigation;
    await hideLoadingPage()(dispatch);
    const url = `${Urls.questions(basePath)}?question=${questionId}`;
    dispatch(routerPush(url));
  };

export const showLoadingPage =
  (messages: LoadingPageMessages, duration: number, callback?: () => void) => async (dispatch: Dispatch) => {
    dispatch(navigationSlice.actions.setLoadingPage({ messages, isVisible: true }));
    loadingPageSetTimeoutPtr = setTimeout(() => {
      dispatch(navigationSlice.actions.setLoadingPage({ isVisible: false }));
      callback?.();
    }, duration);
  };
