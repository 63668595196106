import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SerializedNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';

import { Step } from '../../Models/Step';

export type StepState = {
  currentStep: Step | null;
  nextStep: Step | null;
  isLoading: boolean;
  loadingStepId: string | null;
  hasErrors: boolean;
  nodeIdToAnswerPathMap: SerializedNodeIdToAnswerPathMap | null;
};

const defaultState = {
  currentStep: null,
  nextStep: null,
  isLoading: false,
  loadingStepId: null,
  hasErrors: false,
  nodeIdToAnswerPathMap: null,
};

type StepPayload = {
  step: Step | null;
};

type IsLoadingPayload = {
  isLoading: boolean;
  stepId: string | null;
};

type HasErrorsPayload = {
  hasErrors: boolean;
};

type NodeIdToAnswerPathMapPayload = {
  nodeIdToAnswerPathMap: SerializedNodeIdToAnswerPathMap | null;
};

type ActionsType = {
  setCurrentStep: (state: StepState, { payload }: PayloadAction<StepPayload>) => void;
  setNextStep: (state: StepState, { payload }: PayloadAction<StepPayload>) => void;
  setIsLoading: (state: StepState, { payload }: PayloadAction<IsLoadingPayload>) => void;
  reset: (state: StepState) => void;
  setHasErrors: (state: StepState, { payload }: PayloadAction<HasErrorsPayload>) => void;
  setNodeIdToAnswerPathMap: (state: StepState, { payload }: PayloadAction<NodeIdToAnswerPathMapPayload>) => void;
};

export const stepSlice = createSlice<StepState, ActionsType>({
  name: 'consumerFlow/step',
  initialState: defaultState,
  reducers: {
    setCurrentStep(state: StepState, { payload }: PayloadAction<StepPayload>) {
      state.currentStep = payload.step;
    },
    setNextStep(state: StepState, { payload }: PayloadAction<StepPayload>) {
      state.nextStep = payload.step;
    },
    setIsLoading(state: StepState, { payload }: PayloadAction<IsLoadingPayload>) {
      state.isLoading = payload.isLoading;
      state.loadingStepId = payload.stepId;
    },
    reset(state: StepState) {
      state.isLoading = false;
      state.loadingStepId = null;
      state.currentStep = null;
      state.nextStep = null;
      state.nodeIdToAnswerPathMap = null;
    },
    setHasErrors(state: StepState, { payload }: PayloadAction<HasErrorsPayload>) {
      state.hasErrors = payload.hasErrors;
    },
    setNodeIdToAnswerPathMap(state: StepState, { payload }: PayloadAction<NodeIdToAnswerPathMapPayload>) {
      state.nodeIdToAnswerPathMap = payload.nodeIdToAnswerPathMap;
    },
  },
});
