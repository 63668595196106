import { AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import config from '@breathelife/config/frontend';
import { nodeIdToAnswerPathMap } from '@breathelife/insurance-form-builder';
import { NodeIdAnswersResolver, SubmissionSubsectionLayout } from '@breathelife/questionnaire-engine';
import { usePolling } from '@breathelife/react-hooks';
import { setA11yMessage } from '@breathelife/redux';
import { Language } from '@breathelife/types';

import { CarrierContext } from 'Context/CarrierContext';
import { useCxSelector } from 'Hooks/useCxSelector';
import { a11yPageTitle } from 'Localization/Localizer';
import Urls from 'Navigation/Urls';
import { processApplicationSubmission } from 'Redux/Submission/SubmissionOperations';
import ApiService from 'Services/ApiService';

import { SubmissionWithFlowLayout } from './FlowLayout/SubmissionWithFlowLayout';
import { SubmissionView } from './SubmissionView';

export type Props = {
  language: Language;
  layout?: SubmissionSubsectionLayout;
};

const DEFAULT_PDF_FETCH_DURATION_IN_SECONDS = 60;

export function SubmissionViewContainer(props: Props): React.ReactElement | null {
  setA11yMessage(a11yPageTitle('submit'));

  const temporaryInsurancePdfUrl = config.get<string | undefined>('carrier.temporaryInsurancePdfUrl');
  const { pdfFetchDuration, features } = useContext(CarrierContext);
  const pdfFetchDurationMs = (pdfFetchDuration || DEFAULT_PDF_FETCH_DURATION_IN_SECONDS) * 1000;
  const pollingIntervalMs = 5000;
  const retryCount = Math.trunc(pdfFetchDurationMs / pollingIntervalMs);

  const dispatch = useDispatch();
  const basePath = useCxSelector((state) => state.consumerFlow.navigation.basePath);

  const applicationId = useCxSelector((store) => store.consumerFlow.insuranceApplication?.insuranceApplication?.id);
  const confirmationNumber = useCxSelector(
    (store) => store.consumerFlow.insuranceApplication?.insuranceApplication?.confirmationNumber
  );
  const submissionState = useCxSelector((store) => store.consumerFlow.submission);
  const { cryptoMaterial, outcome, errorStep, isSubmissionStarted, isSubmissionSuccess, isSubmissionResolved } =
    submissionState;

  const insuranceApplication = useCxSelector((store) => store.consumerFlow.insuranceApplication);
  const {
    carrierInfo: { vendorCodeNodeId },
  } = useContext(CarrierContext);
  const answers = insuranceApplication.insuranceApplication?.answers;
  const vendorCode =
    (vendorCodeNodeId &&
      new NodeIdAnswersResolver(nodeIdToAnswerPathMap).getAnswer(answers ?? {}, vendorCodeNodeId, {})) ??
    '';

  const [isLoading, setIsLoading] = useState(false);
  const [applicationPdfUrl, setApplicationPdfUrl] = useState('');
  const [hasPdfError, setHasPdfError] = useState(false);

  const signatureType = features.signature.enabled ? features.signature.type : undefined;

  const onFetch = (): Promise<AxiosResponse> => {
    return ApiService.fetchApplicationPdf(applicationId ?? '', { polling: '1' });
  };

  const isPolling = usePolling<{ url: string }>({
    onFetch,
    interval: pollingIntervalMs,
    retryCount,
    retryOnFailure: false,
    skipPolling: isSubmissionResolved && !isSubmissionSuccess,
    onSuccess: (response) => {
      const pdfUrl = response.data.url;
      const hasPdfUrl = !!pdfUrl;
      if (hasPdfUrl) setApplicationPdfUrl(pdfUrl);
      return hasPdfUrl;
    },
    onFailure: () => {
      setHasPdfError(true);
    },
  });

  useEffect(() => {
    if (!applicationId || isSubmissionResolved) {
      setIsLoading(false);
      return;
    }

    if (!isSubmissionStarted && signatureType) {
      setIsLoading(true);
      dispatch(processApplicationSubmission(applicationId, signatureType, vendorCode));
    }
  }, [
    dispatch,
    applicationId,
    isSubmissionStarted,
    isSubmissionSuccess,
    errorStep,
    isSubmissionResolved,
    signatureType,
    vendorCode,
  ]);

  if (!applicationId) {
    return <Redirect to={Urls.fourOhFour(basePath)} />;
  }

  if (props.layout === SubmissionSubsectionLayout.flow) {
    return (
      <SubmissionWithFlowLayout
        isLoading={isLoading || isPolling}
        isSubmissionSuccess={isSubmissionSuccess && !hasPdfError}
        outcome={outcome}
        language={props.language}
        cryptoMaterial={cryptoMaterial}
        confirmationNumber={confirmationNumber}
        temporaryInsurancePdfUrl={temporaryInsurancePdfUrl}
        applicationPdfUrl={applicationPdfUrl}
        layout={props.layout}
      />
    );
  }

  return (
    <SubmissionView
      isLoading={isLoading || isPolling}
      isSubmissionSuccess={isSubmissionSuccess && !hasPdfError}
      outcome={outcome}
      language={props.language}
      cryptoMaterial={cryptoMaterial}
      confirmationNumber={confirmationNumber}
      temporaryInsurancePdfUrl={temporaryInsurancePdfUrl}
      applicationPdfUrl={applicationPdfUrl}
    />
  );
}
