import styled from 'styled-components';

import { Button } from '@breathelife/ui-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const ErrorImage = styled.img`
  width: 400px;
  height: auto;
  margin: 0 auto;
  display: block;
  margin-bottom: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    width: 280px;
    margin-bottom: 60px;
  }
`;

export const Description = styled.p`
  font-size: ${(props) => props.theme.fonts.size.mobile.medium};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.max}) {
    font-size: ${(props) => props.theme.fonts.size.medium};
  }
`;

export const StartNowButton = styled(Button)`
  &&&& {
    margin-top: 20px;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      margin-top: 40px;
    }
  }
`;
