import { MuiThemeProvider } from '@material-ui/core/styles';
import { configureScope, Scope } from '@sentry/browser';
import _ from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FlattenSimpleInterpolation, ThemeProvider } from 'styled-components';

import { DeepPartial, Language, Theme } from '@breathelife/types';
import {
  ErrorBoundary,
  Loader,
  PopupProvider,
  SchedulingTimeFrameConfig,
  SharedTheme,
  StripeElementsProvider,
} from '@breathelife/ui-components';

import { DebugToolbarContainer } from 'Components/DebugToolbar/DebugToolbarContainer';
import { CarrierContext, ContextProps, defaultFeatures } from 'Context/CarrierContext';
import { getTraceId } from 'Helpers/monitoring';
import { useCxSelector } from 'Hooks/useCxSelector';
import { getCurrentLocale, initCarrierLanguages, setLocale, setTranslations } from 'Localization/Localizer';
import { Dictionaries } from 'Localization/index';
import { ErrorPageContainer } from 'Pages/ErrorPage/ErrorPageContainer';
import { ReactQueryClientProvider } from 'ReactQuery';
import * as SettingsOperations from 'Redux/Settings/SettingsOperations';
import * as ThemeOperations from 'Redux/Theme/ThemeOperations';
import { Root } from 'Root/Root';
import { GlobalStyle } from 'Styles/GlobalStyle';
import { createMuiTheme } from 'Styles/MuiTheme';

import { ConsumerFlowConfig, StepIdMetadataMap } from './types';

export { ConsumerFlowConfig, StepIdMetadataMap } from './types';

type Props = ContextProps & {
  theme: SharedTheme;
  colorizeTheme?: (theme: Theme) => SharedTheme;
  globalStyle?: FlattenSimpleInterpolation;
  config: ConsumerFlowConfig;
  basePath?: string;
  lastStepsIds: string[];
  enableNewApplicationRoute: boolean;
  enableLoadLanguageSettingsFromDb?: boolean;
  enableLoadCarrierNamesFromDb?: boolean;
  enableLoadProductInformationFromDb?: boolean;
  enableSalesDecisionRules?: boolean;
  endOfFlowUrl: string;
  localizedStrings: DeepPartial<Dictionaries>;
  schedulingTimeFrames?: SchedulingTimeFrameConfig;
  stepIdMetadataMap?: StepIdMetadataMap;
  carrierLanguages?: Language[];
  // Once MGAs are in the db, this property can be removed
  // because the interlocutor status should be directly available
  // from the application assignee
  interlocutorIds?: string[];
};

function ConsumerFlowComponent(props: Props): React.ReactElement {
  const { theme } = useCxSelector((store) => store.consumerFlow.theme);
  const { settings } = useCxSelector((store) => store.consumerFlow.settings);
  const dispatch = useDispatch();

  const {
    config: propConfig,
    theme: propTheme,
    colorizeTheme,
    enableLoadLanguageSettingsFromDb,
    enableLoadCarrierNamesFromDb,
    enableLoadProductInformationFromDb,
    enableSalesDecisionRules,
  } = props;

  useEffect(() => {
    dispatch(ThemeOperations.fetchTheme());
  }, [dispatch]);

  useEffect(() => {
    if (enableLoadLanguageSettingsFromDb || enableLoadCarrierNamesFromDb) {
      dispatch(SettingsOperations.fetchSettings());
    }
  }, [dispatch, enableLoadLanguageSettingsFromDb, enableLoadCarrierNamesFromDb]);

  const { enabledLanguages, defaultLanguage } = useMemo(
    () => (enableLoadLanguageSettingsFromDb && settings ? settings.data : propConfig),
    [enableLoadLanguageSettingsFromDb, propConfig, settings]
  );

  useEffect(() => {
    setTranslations(props.localizedStrings);

    if (enableLoadLanguageSettingsFromDb && enabledLanguages?.length) {
      initCarrierLanguages(enabledLanguages);
    } else {
      initCarrierLanguages(props.carrierLanguages ?? [Language.en]);
    }

    const { lang } = queryString.parse(location.search) as Record<string, Language | undefined>;
    if (lang) {
      setLocale(lang);
    }

    if (enabledLanguages && !enabledLanguages.includes(getCurrentLocale())) {
      if (!defaultLanguage) {
        throw new Error('Default language not set in carrier config');
      }

      setLocale(defaultLanguage);
    }

    configureScope((scope: Scope) => {
      const traceId = getTraceId();
      scope.setTag('traceId', traceId);
    });
  }, [
    props.localizedStrings,
    props.carrierLanguages,
    enabledLanguages,
    defaultLanguage,
    enableLoadLanguageSettingsFromDb,
  ]);

  const consumerTheme = useMemo(
    () => (theme && colorizeTheme ? colorizeTheme(theme) : propTheme),
    [propTheme, theme, colorizeTheme]
  );

  const features = props.features ?? defaultFeatures;

  if (!theme) return <Loader color='#000000' />;

  return (
    <ReactQueryClientProvider>
      <ThemeProvider theme={consumerTheme}>
        <MuiThemeProvider theme={createMuiTheme(consumerTheme)}>
          <PopupProvider>
            <CarrierContext.Provider
              value={{
                theme: props.theme,
                features,
                carrierInfo: {
                  ...props.carrierInfo,
                  companyName:
                    enableLoadCarrierNamesFromDb && settings
                      ? settings.data.carrierNames[getCurrentLocale()]
                      : props.carrierInfo.companyName,
                  logo: theme ? theme.logoImgUrl : props.carrierInfo.logo,
                  logoCompact: theme ? theme.compactLogoImgUrl : props.carrierInfo.logoCompact,
                },
                enableLoadLanguageSettingsFromDb,
                enableLoadProductInformationFromDb,
                enableLoadCarrierNamesFromDb,
                enableSalesDecisionRules,
                cloudinaryConfig: propConfig.cloudinary,
                pdfFetchDuration: propConfig.pdfFetchDuration,
                layout: props.layout,
                images: props.config.images,
                iconMap: props.config.iconMap ?? {},
                carrierCustomizations: props.carrierCustomizations,
                canRestartAfterSessionExpired: props.config.canRestartAfterSessionExpired,
                enableHeaderProductSummary: props.config.enableHeaderProductSummary ?? false,
                interlocutorIds: props.interlocutorIds,
                enableQuestionnaireDebugTools: props.enableQuestionnaireDebugTools,
                displayFullAdvisorNameOnAdvisorGreetingMessage:
                  !!propConfig.displayFullAdvisorNameOnAdvisorGreetingMessage,
              }}
            >
              <ErrorBoundary renderErrorComponent={() => <ErrorPageContainer errorCode='500' />}>
                <DebugToolbarContainer />
                {/*
                 * @TODO: The rendering of StripeElementProvider will be edited in DEV-11626
                 * to provide a parent wrapper component and better isolate the following logic
                 */}
                <StripeElementsProvider config={features.payments}>
                  <Root
                    basePath={props.basePath ?? '/plan'}
                    lastStepsIds={props.lastStepsIds}
                    enableNewApplicationRoute={props.enableNewApplicationRoute}
                    endOfFlowUrl={props.endOfFlowUrl}
                    schedulingTimeFrames={props.schedulingTimeFrames}
                    stepIdMetadataMap={props.stepIdMetadataMap}
                  />
                </StripeElementsProvider>
              </ErrorBoundary>
            </CarrierContext.Provider>
          </PopupProvider>
        </MuiThemeProvider>
        <GlobalStyle carrierStyle={props.globalStyle} />
      </ThemeProvider>
    </ReactQueryClientProvider>
  );
}

export const ConsumerFlow = React.memo(ConsumerFlowComponent, _.isEqual);

export * from './Redux/Store';
export * from './Models/Layout';
export * from './Models/ApplicationAssignee';
export * from './Components/TitleHelmet/TitleHelmetContainer';
export * from './Components/GenericLinkLanding/GenericLinkLanding';
export * from './Layouts/PlanFinder/Footer';
export * from './Layouts/PlanFinder/Header';
export * from './Layouts/Centered/Layout';
export * from './Models/Step';
export { getOtherLocales } from './Localization/Localizer';
export { switchLanguage } from './Redux/SwitchLanguage/SwitchLanguageOperations';
export { createTrackerMiddleware } from './Redux/Middlewares/createTrackerMiddleware';
export { Language } from '@breathelife/types';
export * from './Models/Features';
export { CreateMockApplicationView } from './Pages/CreateMockApplicationPage/CreateMockApplicationView';
